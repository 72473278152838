import { render, staticRenderFns } from "./cartRight.vue?vue&type=template&id=63c16fae&scoped=true&"
import script from "./cartRight.vue?vue&type=script&lang=js&"
export * from "./cartRight.vue?vue&type=script&lang=js&"
import style0 from "./cartRight.vue?vue&type=style&index=0&id=63c16fae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63c16fae",
  null
  
)

export default component.exports