<template> 
  <div style="width: 100%; height: 100%">
    <div class="top" style="width: 100%; height: 10%">
      <p style="
          width: 100%;
          height: 10%;
          display: flex;
          justify-content: space-between;
        ">
        <span style="color: #acaebf">{{ this.tableTitle }}</span>
        <span style="color: #acaebf; cursor: pointer" @click="clickOn">{{$t('SecurityPosture.tableList.more')}}
          <i class="el-icon-d-arrow-right"></i>

          <!-- <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiantou_xiangyouliangci_o"></use>
          </svg> -->
        </span>
      </p>
    </div>
    <div class="bottom" style="width: 100%; height: 80%; margin-left: 10px">
      <!-- 告警信息表格 -->
      <el-table v-if="oneTable" size="small" class="tableClass5 sort_table" ref="scroll_table" :data="tableData" @cell-mouse-enter="mouseEnter"
        @cell-mouse-leave="mouseLeave" style="width: 98%; font-size: 12px">
        <el-table-column min-width="45%" prop="devicename" :label="$t('public.DeviceName')" class="a" :show-overflow-tooltip="true" style="margin-left: 10px">
          <template v-slot="{ row }">
            <template v-if="row.devicename">{{ row.devicename }} </template>
            <template v-if-else="row.deviceName">{{ row.deviceName }}
            </template>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="actiontype" :label="$t('public.BehaviorType')" :show-overflow-tooltip="true">
          <template v-slot="{ row }">
            <template v-if="row.actiontype == 'S_CHECK_LOCATION_FRAUD'"><span>{{$t('SecurityPosture.tableList.AlarmInformationForm.LocationFraud')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_DOMAIN'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.DomainNameFraud')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_INJECT_STATUS'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.InjectionAttack')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_DEBUG_STATUS'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.DebuggingBehavior')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_MEMORY_CHANGE'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.MemoryTampering')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_PLUGIN'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.ProgramPlugIn')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_SIGNATURE'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.ApplicationCracking')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_ABNORMAL_CA'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.HttpsHijacking')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_WIFI_PROXY'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.HttpProxy')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_MULTI_APK'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.ApplicationMultiplex')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FRAME_ATTACK'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.FrameAttack')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FREQUENCY_ACCOUNT'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.HighFrequencyChangeAccount')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FREQUENCY_IP'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.HighFrequencyChangeIP')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FREQUENCY_LOCATION'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.HighFrequencyChangePosition')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_FREQUENCY_RESTART'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.HighFrequencyStartingApplication')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_DANGER_APPS'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.RiskApplication')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_ROOT_STATUS'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.ROOTJailbreak')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_FRAME_ATTACH'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.FrameSoftware')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_ADB_ENABLED'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.USBDebugging')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_VM_STATUS'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.simulator')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_ALLOW_MOCK_LOCATION'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.SimulatedPositionAllowed')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_SYS_USER_CA'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.RootCertificateException')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_CUSTOM_ROM'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.CustomROM')}}</span>
            </template> 
            <template v-else-if="row.actiontype == 'S_CHECK_SYSTEM_TIME'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.SystemTimeException')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_SCREEN_PASSWORD'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.TheScreenLockPasswordPsNotEnabled')}}</span>
            </template> 
            <template v-else-if="row.actiontype == 'S_MACOS_STATUS'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.macOS')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_WINDOWS_STATUS'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.Windows')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_VPN_STATUS'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.VPN')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CLOUD_PHONE_STATUS'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.CloudPhone')}}</span>
            </template>
            <template v-else-if="row.actiontype == 'S_CHECK_DANGER_IP'">
              <span>{{$t('SecurityPosture.tableList.AlarmInformationForm.RiskIP')}}</span>
            </template>
            <!-- <template v-else-if="row.actiontype == 'S_CHECK_SCREENSHOT_STATUS'">
              <span>截屏阻断解除</span>
            </template> -->
            <template v-else-if="row.actiontype">
              <span>{{ row.actiontype }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :show-overflow-tooltip="true" :label="$t('public.Behaviorlevel')">
          <template slot-scope="scope">
            <el-button type="text" style="color: #d9001b; cursor: auto" v-if="scope.row.level == '3'">{{$t('SecurityPosture.tableList.AlarmInformationForm.high')}}
            </el-button>
            <el-button type="text" style="color: #f59a23; cursor: auto" v-if="scope.row.level == '2'">{{$t('SecurityPosture.tableList.AlarmInformationForm.medium')}}
            </el-button>
            <el-button type="text" style="color: #00cdcd; cursor: auto" v-if="scope.row.level == '1'">{{$t('SecurityPosture.tableList.AlarmInformationForm.low')}}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.ApplicationName')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column min-width="35%" prop="loginname" :label="$t('public.Username')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column min-width="35%" prop="groupfullname" :label="$t('public.Section')" :show-overflow-tooltip="true">
          <template v-slot="{row}">
            <span v-if="row.groupfullname">{{row.groupfullname.substr(groupFullNameShowByIndex)}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 违规行为表格 -->
      <el-table v-if="twoTable" size="small" class="tableClass5 sort_table" ref="scroll_table" :data="tableData" @cell-mouse-enter="mouseEnter"
        @cell-mouse-leave="mouseLeave"  style="width: 98%; font-size: 12px">
        <el-table-column min-width="35%" prop="devicename" :label="$t('public.DeviceName')" class="a" :show-overflow-tooltip="true" style="margin-left: 10px">
          <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="actiontype" :label="$t('public.BehaviorType')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :label="$t('public.Behaviorlevel')">
          <template slot-scope="scope">
            <el-button type="text" style="color: #d9001b; cursor: auto" v-if="scope.row.level == '3'">{{$t('SecurityPosture.tableList.AlarmInformationForm.high')}}
            </el-button>
            <el-button type="text" style="color: #f59a23; cursor: auto" v-if="scope.row.level == '2'">{{$t('SecurityPosture.tableList.AlarmInformationForm.medium')}}
            </el-button>
            <el-button type="text" style="color: #00cdcd; cursor: auto" v-if="scope.row.level == '1'">{{$t('SecurityPosture.tableList.AlarmInformationForm.low')}}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.Position')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.location }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="username" :label="$t('public.InternetMethod')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.netType }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="groupfullname" :label="$t('public.Username')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>

        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 威胁行为表格 -->
      <el-table v-if="threeTable" size="small" class="tableClass5 sort_table" ref="scroll_table" :data="tableData" @cell-mouse-enter="mouseEnter"
        @cell-mouse-leave="mouseLeave"  style="width: 98%; font-size: 12px">
        <el-table-column min-width="35%" prop="devicename" :label="$t('public.DeviceName')" class="a" :show-overflow-tooltip="true" style="margin-left: 10px">
          <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="type" :label="$t('public.BehaviorType')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :show-overflow-tooltip="true" :label="$t('public.Behaviorlevel')">
          <template slot-scope="scope">
            <el-button type="text" style="color: #d9001b; cursor: auto" v-if="scope.row.level == '3'">{{$t('SecurityPosture.tableList.AlarmInformationForm.high')}}
            </el-button>
            <el-button type="text" style="color: #f59a23; cursor: auto" v-if="scope.row.level == '2'">{{$t('SecurityPosture.tableList.AlarmInformationForm.medium')}}
            </el-button>
            <el-button type="text" style="color: #00cdcd; cursor: auto" v-if="scope.row.level == '1'">{{$t('SecurityPosture.tableList.AlarmInformationForm.low')}}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.ApplicationName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="loginname" :label="$t('public.Username')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="groupfullname" :label="$t('public.Section')" :show-overflow-tooltip="true">
          <template v-slot="{row}">
            <span v-if="row.groupfullname">{{row.groupfullname.substr(groupFullNameShowByIndex)}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 敏感行为表格 -->
      <el-table v-if="fourTable" size="small" class="tableClass5 sort_table" ref="scroll_table" :data="tableData" @cell-mouse-enter="mouseEnter"
        @cell-mouse-leave="mouseLeave"  style="width: 98%; font-size: 12px">
        <el-table-column min-width="35%" prop="devicename" :label="$t('public.DeviceName')" class="a" :show-overflow-tooltip="true" style="margin-left: 10px">
          <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="infoType" :label="$t('public.BehaviorType')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.infoType }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :label="$t('public.Behaviorlevel')">
          <template slot-scope="scope">
            <el-button type="text" style="color: #d9001b; cursor: auto" v-if="scope.row.level == '3'">{{$t('SecurityPosture.tableList.AlarmInformationForm.high')}}
            </el-button>
            <el-button type="text" style="color: #f59a23; cursor: auto" v-if="scope.row.level == '2'">{{$t('SecurityPosture.tableList.AlarmInformationForm.medium')}}
            </el-button>
            <el-button type="text" style="color: #00cdcd; cursor: auto" v-if="scope.row.level == '1'">{{$t('SecurityPosture.tableList.AlarmInformationForm.low')}}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.ApplicationName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="loginname" :label="$t('public.Username')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="groupfullname" :label="$t('public.Section')" :show-overflow-tooltip="true">
          <template v-slot="{row}">
            <span v-if="row.groupfullname">{{row.groupfullname.substr(groupFullNameShowByIndex)}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 安全事件表格 -->
      <el-table v-if="fiveTable" size="small" class="tableClass5 sort_table" ref="scroll_table" :data="tableData" @cell-mouse-enter="mouseEnter"
        @cell-mouse-leave="mouseLeave"  style="width: 98%; font-size: 12px">
        <el-table-column min-width="35%" prop="deviceName" :label="$t('public.DeviceName')" class="a" :show-overflow-tooltip="true" style="margin-left: 10px">
          <template slot-scope="scope">
            {{ scope.row.deviceName }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="eventName" :label="this.$t('SecurityPosture.tableList.SecurityIncident.EventName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.eventName }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :show-overflow-tooltip="true" :label="$t('public.Behaviorlevel')">
          <template slot-scope="scope">
            <el-button type="text" style="color: #d9001b; cursor: auto" v-if="scope.row.level == '3'">{{$t('SecurityPosture.tableList.AlarmInformationForm.high')}}
            </el-button>
            <el-button type="text" style="color: #f59a23; cursor: auto" v-if="scope.row.level == '2'">{{$t('SecurityPosture.tableList.AlarmInformationForm.medium')}}
            </el-button>
            <el-button type="text" style="color: #00cdcd; cursor: auto" v-if="scope.row.level == '1'">{{$t('SecurityPosture.tableList.AlarmInformationForm.low')}}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.ApplicationName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="loginname" :label="$t('public.Username')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="time" :label="$t('public.GenerationTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.time }}
          </template>
        </el-table-column>

        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- SDK行为表格 -->
      <el-table v-if="sixTable" size="small" class="tableClass5 sort_table" ref="scroll_table" :data="tableData" @cell-mouse-enter="mouseEnter"
        @cell-mouse-leave="mouseLeave"  style="width: 98%; font-size: 12px;z-index: 10;">
        <el-table-column min-width="35%" prop="devicename" :label="$t('public.DeviceName')" class="a" :show-overflow-tooltip="true" style="margin-left: 10px">
          <template slot-scope="scope">
            <!-- {{ scope.row.devicename }} -->
            {{ scope.row.devicename }}

          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="sdkname" :label="this.$t('SecurityPosture.tableList.SDKList.sdkName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
           <el-button type="text" style="cursor:text">{{ scope.row.sdkname }}</el-button> 
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :show-overflow-tooltip="true" :label="$t('public.BehaviorType')">
          <template slot-scope="scope">
            {{ scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.ApplicationName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="username" :label="$t('public.SystemVersion')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.osVersion }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="groupfullname" :label="$t('public.Username')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>

        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 运行信息表格 -->
      <el-table v-if="sevenTable" size="small" class="tableClass5 sort_table" ref="scroll_table" :data="tableData" @cell-mouse-enter="mouseEnter"
        @cell-mouse-leave="mouseLeave" style="width: 98%; font-size: 12px">
        <el-table-column min-width="35%" prop="devicename" :label="$t('public.ApplicationName')" class="a" :show-overflow-tooltip="true" style="margin-left: 10px">
          <template slot-scope="scope">
            {{ scope.row.appname }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="sdkname" :label="$t('public.ApplicationVersion')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <!-- {{ scope.row.versionname }} -->
            <el-button type="text" style="cursor: auto"> {{ scope.row.versionname }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="name" :label="$t('public.DeviceName')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="username" :label="$t('SecurityPosture.tableList.RunningInformation.NumberOfStarts')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.runcount  }}{{ $t('SecurityPosture.tableList.RunningInformation.time') }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="groupfullname" :label="$t('SecurityPosture.tableList.RunningInformation.DurationOfUse')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ $common.formatDuring(scope.row.runtime) }}
          </template>
        </el-table-column>
        <el-table-column min-width="35%" prop="level" :show-overflow-tooltip="true" :label="$t('SecurityPosture.tableList.RunningInformation.trafficOfUse')">
          <template slot-scope="scope">
            {{ $common.formatFlow(scope.row.useflow) }}
          </template>
        </el-table-column>
        <el-table-column prop="createtime" :label="$t('public.ReportingTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.createtime == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.createtime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--卡片配置弹框 -->
    <Eldialog :title="'选择图表'" :width="'460px'" :btnTitle="'确定'" :flagbtn="true" :cancelBtn="true" :dialogVisible="statusVisible" @handleClose="handleClose" :determineBtn="true" :num="'170px'" @determine="determine">
      <el-form ref="form" :model="form" label-width="120px" style="margin-top: 30px">
        <!-- 统计内容 -->
        <el-form-item label="统计内容：">
          <el-select v-model="optionValue" @change="content($event)" placeholder="请选择">
            <el-option v-for="item in optionData" :key="item.value" :label="$t(`SecurityPosture.tableList.TableDropDownBox.${item.label}`)" :value="{ value: item.value, label: item.label }">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Eldialog>
  </div>
</template>

<script>
import Eldialog from '@/components/elDialog' //详情弹框
import '../../../assets/iconfont/iconfont.js' //小齿轮设置的icon
import options from '@/utils/country-level3-data' //三级城市数据
import moment from 'moment'
import elementResizeDetectorMaker from 'element-resize-detector' //获取div宽高
export default {
  // 组件
  components: {
    Eldialog
  },
  props: {
    nameCityText: {
      type: Object,
      default: () => {}
    },
    pageContent: {
      type: Object,
      default: () => {}
    },
    newTitle: {
      type: String,
      default: ''
    },
    queryCount: {
      type: String
    }
  },
  data() {
    return {
      timer:null,
      categoryDataList:[],
      autoPlay:true,
      cityarrs: [],
      cityOptions: options, // 城市数据
      taDay: '', //结束时间--（当天时间）
      startDay: '', //开始时间
      queryObj: '', //卡片的数据
      saveAllList: [],
      groupFullNameShowByIndex: '', //用户部门截取的下标
      oneTable: false,
      twoTable: false,
      threeTable: false,
      fourTable: false,
      fiveTable: false,
      sixTable: false,
      sevenTable: false,
      saveAllList: [], //路由
      name: '我是子组件里面的数据', //我是子组件里面的数据
      tableTitle: '', //改变后title
      metaTableTitle: '', //下拉选选中的值描述
      metaDataValue: '', //下拉选选中的值
      dataValue: '', // 改变后的值
      statusVisible: false, //弹框默认关闭
      tableData: [],
      // 违规行为 行为类型  关系映射
      typeOptions: [
        { type: '', label: this.$t('SecurityPosture.tableList.violationType.TypeOfBehavior') },
        { type: '0', label: this.$t('SecurityPosture.tableList.violationType.DeviceJailbreak') },
        { type: '8', label: this.$t('SecurityPosture.tableList.violationType.SIMCardChange') },
        { type: '13', label: this.$t('SecurityPosture.tableList.violationType.SDCardChange') },
        { type: '9', label: this.$t('SecurityPosture.tableList.violationType.IllegalOutreach') },
        { type: '5', label: this.$t('SecurityPosture.tableList.violationType.geofencing') },
        { type: '1', label: this.$t('SecurityPosture.tableList.violationType.SystemVersion') },
        { type: '7', label: this.$t('SecurityPosture.tableList.violationType.FlowAnomaly') },
        { type: '14', label: this.$t('SecurityPosture.tableList.violationType.FlowAnomaly') },
        { type: '15', label: this.$t('SecurityPosture.tableList.violationType.StorageSpace') },
        { type: '12', label: this.$t('SecurityPosture.tableList.violationType.StorageSpace') },
        { type: '2', label: this.$t('SecurityPosture.tableList.violationType.ApplicationBlacklistAndWhitelist') },
        { type: '33', label: this.$t('SecurityPosture.tableList.violationType.WIFIBlackAndWhiteList') },
        { type: '36', label: this.$t('SecurityPosture.tableList.violationType.SystemFunction') }, 
        { type: 'S_MACOS_STATUS', label: this.$t('SecurityPosture.tableList.violationType.macOS') },
        { type: 'S_WINDOWS_STATUS', label: this.$t('SecurityPosture.tableList.violationType.Windows') },
        { type: 'S_VPN_STATUS', label: this.$t('SecurityPosture.tableList.violationType.VPN') },
        { type: 'S_CLOUD_PHONE_STATUS', label: this.$t('SecurityPosture.tableList.violationType.CloudPhone') }, 
        // { type: 'S_CHECK_SCREENSHOT_STATUS', label: '截屏阻断解除' }
      ],
      // 威胁行为   行为类型  关系映射
      typeOptionsThree: [
        { type: '', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.BehaviorType') },
        { type: 'S_CHECK_INJECT_STATUS', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.InjectionAttack') },
        { type: 'S_CHECK_FRAME_ATTACK', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.FrameAttack') },
        { type: 'S_CHECK_DEBUG_STATUS', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.DebuggingBehavior') },
        { type: 'S_CHECK_LOCATION_FRAUD', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.LocationFraud') },
        { type: 'S_CHECK_DOMAIN', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.DomainNameFraud') },
        { type: 'S_CHECK_PLUGIN', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.ProgramPlugIn') },
        { type: 'S_CHECK_SIGNATURE', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.ApplicationCracking') },
        { type: 'S_CHECK_ABNORMAL_CA', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.HttpsHijacking') },
        { type: 'S_CHECK_WIFI_PROXY', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.HttpProxy') },
        { type: 'S_CHECK_MULTI_APK', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.ApplicationMultiplex') },
        { type: 'S_CHECK_FREQUENCY_ACCOUNT', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.HighFrequencyChangeAccount') },
        { type: 'S_CHECK_FREQUENCY_IP', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.HighFrequencyChangeIP') },
        { type: 'S_CHECK_FREQUENCY_LOCATION', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.HighFrequencyChangePosition') },
        { type: 'S_CHECK_FREQUENCY_RESTART', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.HighFrequencyStartingApplication') },
        { type: 'S_FRAME_ATTACH', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.FrameSoftware') },
        { type: 'S_CHECK_DANGER_APPS', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.RiskApplication') },
        { type: 'S_CHECK_SYS_USER_CA', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.RootCertificateException') },
        { type: 'S_ADB_ENABLED', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.USBDebugging') },
        { type: 'S_CHECK_ROOT_STATUS', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.ROOTJailbreak') },
        { type: 'S_ALLOW_MOCK_LOCATION', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.SimulatedPositionAllowed') },
        { type: 'S_CHECK_VM_STATUS', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.simulator') },
        { type: 'S_CHECK_CUSTOM_ROM', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.CustomROM') }, 
        { type: 'S_CHECK_SYSTEM_TIME', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.SystemTimeException') },
        { type: 'S_CHECK_SCREEN_PASSWORD', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.TheScreenLockPasswordPsNotEnabled') },
        { type: 'S_CHECK_MEMORY_CHANGE', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.MemoryTampering') },
        { type: 'S_MACOS_STATUS', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.macOS') },
        { type: 'S_WINDOWS_STATUS', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.Windows') },
        { type: 'S_VPN_STATUS', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.VPN') },
        { type: 'S_CLOUD_PHONE_STATUS', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.CloudPhone') },
        { type: 'S_CHECK_DANGER_IP', label: this.$t('SecurityPosture.tableList.ThreateningBehavior.CloudPhone') },
        // { type: 'S_CHECK_SCREENSHOT_STATUS', label: '截屏阻断解除' }
      ],
      // 敏感行为   行为类型  关系映射
      typeOptionsFour: [
        { infoType: '', label: this.$t('SecurityPosture.tableList.SensitiveBehavior.BehaviorType') },
        { infoType: 'sensitive', label: this.$t('SecurityPosture.tableList.SensitiveBehavior.ApplicationSensitiveBehavior') },
        { infoType: 'excessed', label: this.$t('SecurityPosture.tableList.SensitiveBehavior.ApplicationOfUltraVires') },
        { type: 'S_MACOS_STATUS', label:this.$t('SecurityPosture.tableList.SensitiveBehavior.macOS') },
        { type: 'S_WINDOWS_STATUS', label: this.$t('SecurityPosture.tableList.SensitiveBehavior.Windows') },
        { type: 'S_VPN_STATUS', label: this.$t('SecurityPosture.tableList.SensitiveBehavior.VPN') },
        { type: 'S_CLOUD_PHONE_STATUS',label: this.$t('SecurityPosture.tableList.SensitiveBehavior.CloudPhone') },
        // { type: 'S_CHECK_SCREENSHOT_STATUS', label: '截屏阻断解除' }
      ],
      //SDK行为
      behavType: [
        {
          selectValue: '',
          label1: this.$t('SecurityPosture.tableList.SDKList.BehaviorType')
        },
        {
          selectValue: 'S_SMS_ACCESS',
          label1: this.$t('SecurityPosture.tableList.SDKList.ReadSMSRecords')
        },
        {
          selectValue: 'S_CONTACTS_ACCESS',
          label1: this.$t('SecurityPosture.tableList.SDKList.ReadAddressBook')
        },
        {
          selectValue: 'S_LOCATION',
          label1: this.$t('SecurityPosture.tableList.SDKList.GetGeographicalLocation')
        },
        {
          selectValue: 'S_GET_SDCARD',
          label1: this.$t('SecurityPosture.tableList.SDKList.ReadStorage')
        },
        {
          selectValue: 'S_GET_DEVICE_INFO',
          label1: this.$t('SecurityPosture.tableList.SDKList.ObtainHardware')
        },
        {
          selectValue: 'S_GET_INSTALLED_APP',
          label1: this.$t('SecurityPosture.tableList.SDKList.ObtainSoftware')
        },
        {
          selectValue: 'S_CAMERA',
          label1: this.$t('SecurityPosture.tableList.SDKList.picturesVideo')
        },
        {
          selectValue: 'S_WIFI_SWITCH',
          label1: this.$t('SecurityPosture.tableList.SDKList.TurnOnWIFI')
        },
        {
          selectValue: 'S_BLUETOOTH_SWITCH',
          label1: this.$t('SecurityPosture.tableList.SDKList.EnableBluetooth')
        },
        { type: 'S_MACOS_STATUS', label1: this.$t('SecurityPosture.tableList.SDKList.macOS') },
        { type: 'S_WINDOWS_STATUS', label1: this.$t('SecurityPosture.tableList.SDKList.Windows') },
        { type: 'S_VPN_STATUS', label1: this.$t('SecurityPosture.tableList.SDKList.VPN') },
        { type: 'S_CLOUD_PHONE_STATUS', label1:this.$t('SecurityPosture.tableList.SDKList.CloudPhone') },
        // { type: 'S_CHECK_SCREENSHOT_STATUS', label: '截屏阻断解除' }
      ],
      form: {
        region: ''
      },
      optionData: [
        {
          //统计内容
          value: '1',
          label: '告警列表'
        },
        {
          value: '2',
          label: '违规行为'
        },
        {
          value: '3',
          label: '威胁行为'
        },
        {
          value: '4',
          label: '敏感行为'
        },
        {
          value: '5',
          label: '安全事件'
        },
        {
          value: '6',
          label: 'SDK行为'
        },
        {
          value: '7',
          label: '运行信息'
        }
      ],
      optionValue: {
        value: '',
        label: ''
      },
      // 全局的值
      allData: ''
    }
  },
  watch: {
    pageContent: {
      handler: function (newVal, oldVal) {
        // if (newVal) {
        this.pageContent = newVal
        this.optionValue.value = this.pageContent.showConf
        this.optionData.forEach((item, index) => {
          if (this.optionValue.value === item.value) {
            this.tableTitle = item.label
            this.optionValue.label = item.label
          }
        })
        // }
      },
      deep: true
      // immediate: true,
    },
    queryCount: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.queryObj = newVal
          var taDay = ''
          var startDay = ''
          if (0 == newVal) {
            taDay = this.UTCformat(this.getdatatime())
            startDay = taDay
          } else if (1 == newVal) {
            taDay = this.UTCformat(this.getdatatime())
            startDay = moment().subtract(6, 'days').format('YYYY-MM-DD')
          } else if (2 == newVal) {
            taDay = this.UTCformat(this.getdatatime())
            startDay = moment().subtract(29, 'days').format('YYYY-MM-DD')
          } else if (3 == newVal) {
            taDay = this.UTCformat(this.getdatatime())
            startDay = moment().subtract(89, 'days').format('YYYY-MM-DD')
          } else {
            console.log('参数有误')
          }
          this.taDay = taDay
          this.startDay = startDay
        }
      },
      deep: true
    }
  },
  created() {
    if (this.nameCityText) {
      let cityArray = this.nameCityText.name
      let options = ['北京', '天津', '上海', '重庆']
      options.forEach((i, index) => {
        if (cityArray) {
          if (cityArray == i) {
            this.cityOptions.forEach((item, index) => {
              if (item.value.includes(cityArray)) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    this.cityarrs.push([item.value, item.value, itemss.value])
                  })
                })
              }
            })
          } else {
            this.cityOptions.forEach((item, index) => {
              if (item.value.includes(cityArray)) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    this.cityarrs.push([item.value, items.value, itemss.value])
                  })
                })
              }
            })
          }
        }
      })
    }
    this.queryObj = this.queryCount
    this.optionValue.value = this.pageContent.showConf
    this.optionData.forEach((item, index) => {
      if (this.optionValue.value === item.value) {
        this.tableTitle = item.label
        this.optionValue.label = item.label
      }
    })
    // this.sendUrl(this.optionValue.value)
  },
  mounted() {
    setTimeout(() => {
      this.sendUrl(this.optionValue.value)
    },3000);
    setTimeout(()=>{
      this.startMove()
    },5000)
    this.saveAllList = [...this.$store.state.selectList]
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    this.saveAllList = [...this.$store.state.selectList]
  },
  methods: {
    mouseEnter() {
      this.autoPlay = false;
    },
    // 鼠标离开
    mouseLeave() {
      this.autoPlay = true;
    },
    startMove() {
      // 拿到表格挂载后的真实DOM
      const table = this.$refs.scroll_table;
      if (table) {
        // 拿到表格中承载数据的div元素
        const divData = table.bodyWrapper;
        if (divData) {
          // if (this.timer) {
          //   clearInterval(this.timer);
          // }
          // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每20毫秒移动1像素)
          this.timer = setInterval(() => {
            if (this.autoPlay) {
              // 元素自增距离顶部1像素
              if (divData.scrollTop >= 400) {
                // const item = this.tableData.shift();
                // this.tableData.push(item);
                divData.scrollTop -= 400;
              }
              divData.scrollTop +=1;
            }
          }, 10);
        }
      }
    },
    //xxxx-xx-xx
    UTCformat(utc) {
      var date = new Date(utc),
        year = date.getFullYear(),
        month =
          date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : '0' + parseInt(date.getMonth() + 1),
        day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate(),
        hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours(),
        minutes =
          date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes(),
        seconds =
          date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()
      var res = year + '-' + month + '-' + day + ' '
      return res
    },
    // 默认显示当天时间
    getdatatime() {
      //默认显示今天
      return new Date().getTime()
    },
    child(v) {
      this.optionValue.value = v.tableInfo.showConf
      this.optionData.forEach((item, index) => {
        if (this.optionValue.value === item.value) {
          this.tableTitle = item.label
          this.optionValue.label = item.label
        }
      })
      this.sendUrl(this.optionValue.value)
      console.log(this.optionValue.value)
    },
    async sendUrl(typeValue) {
      console.log(typeValue)
      let location = null
      let selectedOptions = this.cityarrs
      if (selectedOptions) {
        location = []
        selectedOptions.forEach((element) => {
          location.push(element.flat().join('/'))
        })
      }
      var querayObj = {
        pageSize: 1000,
        currentPage: 1,
        orderColume: 'CREATETIME',
        orderRule: 'DESC',
        location: location
        //reason: '',
        //level: '',
        //name: '',
        //selectedOptions: '',
        //total: 0 //总条数
      }

      if (typeValue === '1') {
        this.oneTable = true
        this.twoTable = false
        this.threeTable = false
        this.fourTable = false
        this.fiveTable = false
        this.sixTable = false
        this.sevenTable = false
        const res = await this.$axios.post(
          '/httpServe/securitySituation/getRealTimeAlarm',
          querayObj,
          true
        )
        let dataArray = []
        if (this.nameCityText.name) {
          res.data.forEach((item, index) => {
            if (item.location.includes(this.nameCityText.name)) {
              dataArray.push(item)
            }
          })
          this.tableData = dataArray
        } else {
          this.tableData = res.data
        }
        return res.data
      } else if (typeValue === '2') {
        this.oneTable = false
        this.twoTable = true
        this.threeTable = false
        this.fourTable = false
        this.fiveTable = false
        this.sixTable = false
        this.sevenTable = false
        const res = await this.$axios.post(
          '/httpServe/violations/getDataInfo',
          querayObj,
          true
        )

        this.tableData = res.data.content
        for (var i = 0; i < this.tableData.length; i++) {
          for (var j = 0; j < this.typeOptions.length; j++) {
            if (this.tableData[i].type == this.typeOptions[j].type) {
              this.tableData[i].type = this.typeOptions[j].label
            }
          }
        }
        let dataArray = []
        if (this.nameCityText.name) {
          res.data.content.forEach((item, index) => {
            if (item.location.includes(this.nameCityText.name)) {
              dataArray.push(item)
            }
          })
          this.tableData = dataArray
        } else {
          this.tableData = res.data.content
        }
        return res.data
      } else if (typeValue === '3') {
        this.oneTable = false
        this.twoTable = false
        this.threeTable = true
        this.fourTable = false
        this.fiveTable = false
        this.sixTable = false
        this.sevenTable = false
        const res = await this.$axios.post(
          '/httpServe/appDanger/getDataInfo',
          querayObj,
          true
        )
        this.tableData = res.data.content
        for (var i = 0; i < this.tableData.length; i++) {
          for (var j = 0; j < this.typeOptionsThree.length; j++) {
            if (this.tableData[i].type == this.typeOptionsThree[j].type) {
              this.tableData[i].type = this.typeOptionsThree[j].label
            }
          }
        }
        let dataArray = []
        if (this.nameCityText.name) {
          res.data.content.forEach((item, index) => {
            if (item.location.includes(this.nameCityText.name)) {
              dataArray.push(item)
            }
          })
          this.tableData = dataArray
        } else {
          this.tableData = res.data.content
        }
        return res.data
      } else if (typeValue === '4') {
        this.oneTable = false
        this.twoTable = false
        this.threeTable = false
        this.fourTable = true
        this.fiveTable = false
        this.sixTable = false
        this.sevenTable = false
        const res = await this.$axios.post(
          '/httpServe/sensitive/getDataInfo',
          querayObj,
          true
        )
        this.tableData = res.data.content
        for (var i = 0; i < this.tableData.length; i++) {
          for (var j = 0; j < this.typeOptionsFour.length; j++) {
            if (
              this.tableData[i].infoType == this.typeOptionsFour[j].infoType
            ) {
              this.tableData[i].infoType = this.typeOptionsFour[j].label
            }
          }
        }
        let dataArray = []
        if (this.nameCityText.name) {
          res.data.content.forEach((item, index) => {
            if (item.location.includes(this.nameCityText.name)) {
              dataArray.push(item)
            }
          })
          this.tableData = dataArray
        } else {
          this.tableData = res.data.content
        }
        return res.data
      } else if (typeValue === '5') {
        this.oneTable = false
        this.twoTable = false
        this.threeTable = false
        this.fourTable = false
        this.fiveTable = true
        this.sixTable = false
        this.sevenTable = false
        const res = await this.$axios.post(
          '/httpServe/appEvent/getDataInfo',
          querayObj,
          true
        )
        let dataArray = []
        if (this.nameCityText.name) {
          res.data.content.forEach((item, index) => {
            if (item.location.includes(this.nameCityText.name)) {
              dataArray.push(item)
            }
          })
          this.tableData = dataArray
        } else {
          this.tableData = res.data.content
        }
        return res.data
      } else if (typeValue === '6') {
        this.oneTable = false
        this.twoTable = false
        this.threeTable = false
        this.fourTable = false
        this.fiveTable = false
        this.sixTable = true
        this.sevenTable = false
        const res = await this.$axios.post(
          '/httpServe/sensitiveSDK/getDataInfo',
          querayObj,
          true
        )
        this.tableData = res.data.content
        for (var i = 0; i < this.tableData.length; i++) {
          for (var j = 0; j < this.behavType.length; j++) {
            if (this.tableData[i].type == this.behavType[j].selectValue) {
              this.tableData[i].type = this.behavType[j].label1
            }
          }
        }
        let dataArray = []
        if (this.nameCityText.name) {
          res.data.content.forEach((item, index) => {
            if (item.location.includes(this.nameCityText.name)) {
              dataArray.push(item)
            }
          })
          this.tableData = dataArray
        } else {
          this.tableData = res.data.content
        }
        return res.data
      } else if (typeValue === '7') {
        this.oneTable = false
        this.twoTable = false
        this.threeTable = false
        this.fourTable = false
        this.fiveTable = false
        this.sixTable = false
        this.sevenTable = true
        const res = await this.$axios.post(
          '/httpServe/appRun/getDataInfo',
          querayObj,
          true
        )
        let dataArray = []
        if (this.nameCityText.name) {
          res.data.content.forEach((item, index) => {
            if (item.location.includes(this.nameCityText.name)) {
              dataArray.push(item)
            }
          })
          this.tableData = dataArray
        } else {
          this.tableData = res.data.content
        }
        return res.data
      }
      //return res.data;
    },

    clickOn() {
      //判断一级 安全监测 名称是否能找到
      let arrName = this.saveAllList.find(
        (item) => item.name == 'SecurityMonitor'
      )
      if (this.optionValue.value === '1') {
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf('Home2') != -1) {
              // this.$router.push("/Home2");
              this.$router.push({
                name: 'Home2',
                query: {
                  key: index + ''
                }
              })
            }
          })
        }
      } else if (this.optionValue.value === '2') {
        // this.$router.push("/ViolationMonitor");
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf('ViolationMonitor') != -1) {
              this.$router.push({
                name: 'ViolationMonitor',
                query: {
                  key: index + '',
                  dataDay: this.nameCityText.dataDay,
                  starttime: this.nameCityText.starttime
                }
              })
            }
          })
        }
      } else if (this.optionValue.value === '3') {
        // this.$router.push("/AppAttackMonitor");
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf('AppAttackMonitor') != -1) {
              this.$router.push({
                name: 'AppAttackMonitor',
                query: {
                  key: index + '',
                  dataDay: this.nameCityText.dataDay,
                  starttime: this.nameCityText.starttime
                }
              })
            }
          })
        }
      } else if (this.optionValue.value === '4') {
        // this.$router.push("/AppSensitiveMonitor");
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf('AppSensitiveMonitor') != -1) {
              this.$router.push({
                name: 'AppSensitiveMonitor',
                query: {
                  key: index + '',
                  dataDay: this.nameCityText.dataDay,
                  starttime: this.nameCityText.starttime
                }
              })
            }
          })
        }
      } else if (this.optionValue.value === '5') {
        // this.$router.push("/SafetyEvent");
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf('SafetyEvent') != -1) {
              this.$router.push({
                name: 'SafetyEvent',
                query: {
                  key: index + '',
                  dataDay: this.nameCityText.dataDay,
                  starttime: this.nameCityText.starttime
                }
              })
            }
          })
        }
      } else if (this.optionValue.value === '6') {
        // this.$router.push("/SDKBehaviour");
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf('SDKBehaviour') != -1) {
              this.$router.push({
                name: 'SDKBehaviour',
                query: {
                  key: index + '',
                  dataDay: this.nameCityText.dataDay,
                  starttime: this.nameCityText.starttime
                }
              })
            }
          })
        }
      } else if (this.optionValue.value === '7') {
        // this.$router.push("/RunInfo");
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf('RunInfo') != -1) {
              this.$router.push({
                name: 'RunInfo',
                query: {
                  key: index + ''
                }
              })
            }
          })
        }
      }
    },
    styleBack({ row, colum, rowIndex }) {
      //row, colum, rowIndex, columnIndex
      return 'padding:3px 0px;height:30px;background:#051555;color:#ffffff;border-bottom:1px solid #064982' //border:1px solid #053772;   表格内容的bottom
    },
    getRowClass({ row, colum, rowIndex, columnIndex }) {
      return 'height:30px;background:#051555;color:red;border-bottom:1px solid #01A4C1;border-right:none' //background:#010D49;color:#01C4D7  表格头的bottom
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:30px;'
    },
    setRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    // 弹框点击关闭事件
    handleClose() {
      this.statusVisible = false // 卡片设置弹框关闭
    },
    //确定的关闭
    determine() {
      this.statusVisible = false //卡片设置弹框关闭
    },
    // 设置卡片弹框
    setUp() {
      this.statusVisible = true
    },
    content(val) {}
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__body-wrapper {
  overflow-x:hidden; 
  overflow-y:hidden;
  height: 240px !important;
}
//分辨率 1920
@media screen and (min-width: 1920px) and (max-width: 2040px) {
  // 表格
  .tableStyle {
    width: 100%;
    height: 39%;
    margin-top: 2%;
  }
  //  高中低级别字体
  .fontSizes {
    font-size: 14px;
  }
  //  回显 字体大小
  .tsize {
    font-size: 16px;
  }
  //  更多字体大小
  .more {
    font-size: 16px;
    margin-right: 4px;
  }
}
//分辨率 1680
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  // 表格
  ::v-deep .tableStyle {
    width: 100%;
    height: 25%;
    margin-top: 2%;
    z-index: 9;
  }
  //  高中低级别字体
  .fontSizes {
    font-size: 14px;
  }
  // 表格 tr 高度
  ::v-deep .el-table .cell {
    line-height: 18px;
  }
  // 回显 字体大小
  .tsize {
    font-size: 16px;
  }
  //  更多  字体大小
  .more {
    font-size: 16px;
    margin-right: 4px;
  }
}
// 分辨率2560
@media screen and (min-width: 2048px) and (max-width: 2560px) {
  // 表格
  ::v-deep .tableStyle {
    width: 100%;
    height: 25%;
    margin-top: 2%;
    z-index: 9;
  }
  //  高中低级别字体
  .fontSizes {
    font-size: 18px;
  }
  // 回显  字体大小
  .tsize {
    font-size: 20px;
  }
  //  更多 字体大小
  .more {
    font-size: 20px;
    margin-right: 4px;
  }
  // 表格tr 高度、 表格每行字体 大小
  ::v-deep .el-table .cell {
    line-height: 55px;
    font-size: 20px;
  }
}
.tableClass5 {
  margin-top: 20px;
  height: 250px !important;
  //  border:red ;
  z-index: 10;
  // 只去除表格的最最底部的横线
}

// 去掉表格单元格边框
.el-table th {
  border: none;
  background-color: #061d60 !important;
}
.el-table td,
.el-table th.is-leaf {
  border: none;
}
// 表格最外边框
.el-table--border,
.el-table--group {
  border: none;
}
// 头部边框
.el-table thead tr th.is-leaf {
  // border: 1px solid #ebeef5;
  border-right: none;
}
.el-table thead tr th:nth-last-of-type(2) {
  border-right: 1px solid #ebeef5;
}
// 表格最外层边框-底部边框
.el-table--border::after,
.el-table--group::after {
  width: 0;
}
.el-table::before {
  width: 0;
}
.el-table .el-table__fixed-right::before,
.el-table__fixed::before {
  width: 0;
}
// 表格有滚动时表格头边框
// .el-table--border th.gutter:last-of-type {
//   border: 1px solid #ebeef5;
//   border-left: none;
// }

::v-deep .el-table th > .cell  {
  /* font-size: 13px; */
  // color: pink !important;
  z-index: 22;
}
// 齿轮设置
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
// color: "#02B9CD";

::v-deep .el-table,
.el-table__expanded-cell {
  background-color: #061d60;
  color: '#02B9CD';
}
::v-deep .el-table tr {
  background-color: #061d60 !important;
}
::v-deep .el-table tbody tr:hover > td {
  background-color: #061d60 !important;
}
::v-deep .el-table__header th, .el-table__header tr {
    background: #061d60!important; 
}
 
::v-deep .el-table–enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: #061d60 !important;
}
::v-deep .el-table__empty-text {
  color: #cec8c8;
}
::v-deep .el-table--small .el-table__cell {
  padding: 3px 0;
}
::v-deep .el-table__body-wrapper .el-table__row:nth-child(odd) {
  color: #FFFFFF !important;
  /* 奇数行的颜色 */
}

::v-deep .el-table__body-wrapper .el-table__row:nth-child(even) {
  color: #FFFFFF !important;
  /* 偶数行的颜色 */
}
::v-deep .el-button--text {
  border-color: transparent;
  color: #FFFFFF !important;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
</style>
