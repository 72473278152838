<template>
  <div style="width: 100%; height: 100%">
    <div
      class="rduis"
      style="
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
      "
    >
      <div class="raduis1" style="width: 100%; height: 100%">
        <!-- 卡片一 -->
        <div style="width: 100%; height: 100%">
          <!-- <crat-left/> -->
          <div
            id="ring1"
            ref="agriEcoDev"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"; //echarts图表引用
import "../../../assets/iconfont/iconfont.js"; //小齿轮设置的icon
import elementResizeDetectorMaker from "element-resize-detector"; //获取div宽高
import moment from "moment";
export default {
  props: {
    pageContent: {
      type: Object,
      default: () => {},
    },
    cardDataSum: {
      type: Object,
      default: () => {},
    },
    queryCount: {
      type: String,
    },
  },
  // 组件
  components: {},
  data() {
    return {
      saveAllList: [],
      taDay: "", //结束时间--（当天时间）
      startDay: "", //开始时间
      record: "1", //记录统计维度
      oneCartValue: 0,
      oneCartName: "",
      cardQuery: {
        value: "",
        name: "",
      },
      statusVisible: false, //默认设置卡片不展示
      form: {
        region: "",
      },
      dimension: [
        {
          //统计维度
          value: "appInfo",
          label: this.$t("SecurityPosture.cardLeftOne.dimension.appInfo"),
        },
        {
          value: "deviceInfo",
          label: this.$t("SecurityPosture.cardLeftOne.dimension.deviceInfo"),
        },
        {
          value: "userInfo",
          label: this.$t("SecurityPosture.cardLeftOne.dimension.userInfo"),
        },
        {
          value: "gatewayInfo",
          label: this.$t("SecurityPosture.cardLeftOne.dimension.gatewayInfo"),
        },
      ],
      // value: '1',
      dimensionValue: "", //下拉框当前展示的维度
      statistical: [], //内容--列表
      statisticalValue: "", //下拉框当前展示的内容
      //应用维度下拉选
      appInfo_statistical: [
        {
          //统计内容
          value: "appRunCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.appInfo_statistical.appRunCountConf"
          ),
        },
        {
          value: "appDangerCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.appInfo_statistical.appDangerCountConf"
          ),
        },
        {
          value: "appEventCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.appInfo_statistical.appEventCountConf"
          ),
        },
        {
          value: "sdkBehaviorCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.appInfo_statistical.sdkBehaviorCountConf"
          ),
        },
      ],
      //设备维度下拉选
      deviceInfo_statistical: [
        {
          value: "deviceSumCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.deviceInfo_statistical.deviceSumCountConf"
          ),
        },
        {
          value: "offLineCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.deviceInfo_statistical.offLineCountConf"
          ),
        },
        {
          value: "onLineCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.deviceInfo_statistical.onLineCountConf"
          ),
        },
        {
          value: "violationsSumCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.deviceInfo_statistical.violationsSumCountConf"
          ),
        },
        {
          value: "onLineRateConf",
          label: this.$t(
            "SecurityPosture.ConfigCardLeftOne.deviceInfo_statistical.onLineRateConf"
          ),
        },
        {
          value: "lostCountConf",
          label: this.$t('SecurityPosture.cardLeftOne.deviceInfo_statistical.lostCountConf'),
        },
        {
          value: "scrapCountConf",
          label: this.$t('SecurityPosture.cardLeftOne.deviceInfo_statistical.scrapCountConf'),
        },
      ],
      //用户维度下拉选
      userInfo_statistical: [
        {
          value: "addUserCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.userInfo_statistical.addUserCountConf"
          ),
        },
        {
          value: "normalUserCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.userInfo_statistical.normalUserCountConf"
          ),
        },
        {
          value: "stopUsingUserCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.userInfo_statistical.stopUsingUserCountConf"
          ),
        },
        {
          value: "lockUserCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.userInfo_statistical.lockUserCountConf"
          ),
        },
        {
          value: "userSumCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.userInfo_statistical.userSumCountConf"
          ),
        },
      ],
      //网关维度下拉选
      gatewayInfo_statistical: [
        {
          value: "deviceConnectCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.gatewayInfo_statistical.deviceConnectCountConf"
          ),
        },
        {
          value: "accessAppCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.gatewayInfo_statistical.accessAppCountConf"
          ),
        },
        {
          value: "userConnectCountConf",
          label: this.$t(
            "SecurityPosture.cardLeftOne.gatewayInfo_statistical.userConnectCountConf"
          ),
        },
      ],
      value1: "1",
    };
  },
  watch: {
    pageContent: {
      handler: function (newVal, oldVal) {
        // if (newVal) {
        this.pageContent = newVal;
        this.allMethods();
      },
      deep: true,
      // immediate: true,
    },
    cardDataSum: {
      handler: function (newVal, oldVal) {
        // if (newVal) {
        this.cardDataSum = newVal;
        this.allMethods();
      },
      deep: true,
      // immediate: true,
    },
    queryCount: {
      handler: function (newVal, oldVal) {
        // newVal = '0';

        var taDay = "";
        var startDay = "";
        if (0 == newVal) {
          taDay = this.UTCformat(this.getdatatime());
          startDay = taDay;
        } else if (1 == newVal) {
          taDay = this.UTCformat(this.getdatatime());
          startDay = moment().subtract(6, "days").format("YYYY-MM-DD");
        } else if (2 == newVal) {
          taDay = this.UTCformat(this.getdatatime());
          startDay = moment().subtract(29, "days").format("YYYY-MM-DD");
        } else if (3 == newVal) {
          taDay = this.UTCformat(this.getdatatime());
          startDay = moment().subtract(89, "days").format("YYYY-MM-DD");
        } else {
          console.log("参数有误");
        }
        this.taDay = taDay;
        this.startDay = startDay;
      },
      deep: true,
    },
  },

  created() {
    var newVal = this.queryCount;
    var taDay = "";
    var startDay = "";
    if (0 == newVal) {
      taDay = this.UTCformat(this.getdatatime());
      startDay = taDay;
    } else if (1 == newVal) {
      taDay = this.UTCformat(this.getdatatime());
      startDay = moment().subtract(6, "days").format("YYYY-MM-DD");
    } else if (2 == newVal) {
      taDay = this.UTCformat(this.getdatatime());
      startDay = moment().subtract(29, "days").format("YYYY-MM-DD");
    } else if (3 == newVal) {
      taDay = this.UTCformat(this.getdatatime());
      startDay = moment().subtract(89, "days").format("YYYY-MM-DD");
    } else {
      console.log("参数有误");
    }
    this.taDay = taDay;
    this.startDay = startDay;
    this.card_1 = this.pageContent;
    // 维度
    var showConfStr = this.card_1.showConf;
    //具体维度下显示内容
    var metaData = this.card_1[showConfStr];
    this.dimensionValue = showConfStr;

    for (const key in metaData) {
      if (metaData[key] == true) {
        //下拉选选中value。用来显示lable
        this.statisticalValue = key;
        if (showConfStr === "appInfo") {
          this.statistical = this.appInfo_statistical;
        }
        if (showConfStr === "deviceInfo") {
          this.statistical = this.deviceInfo_statistical;
        }
        if (showConfStr === "userInfo") {
          this.statistical = this.userInfo_statistical;
        }
        if (showConfStr === "gatewayInfo") {
          this.statistical = this.gatewayInfo_statistical;
        }
        // 循环当前父组件接收的数据  匹配相对应的中文（拿数据）
        this.statistical.forEach((item, index) => {
          if (this.statisticalValue === item.value) {
            this.oneCartName = item.label;
          }
        });
        break;
      }
    }
    //获取数据
    var metaData = this.cardDataSum[showConfStr];

    this.oneCartValue = metaData[this.statisticalValue.split("Conf")[0]];
  },
  mounted() {
    this.saveAllList = [...this.$store.state.selectList];
    this.card_1 = this.pageContent;
    // 维度
    var showConfStr = this.card_1.showConf;
    //具体维度下显示内容
    var metaData = this.card_1[showConfStr];
    this.dimensionValue = showConfStr;

    for (const key in metaData) {
      if (metaData[key] == true) {
        //下拉选选中value。用来显示lable
        this.statisticalValue = key;
        if (showConfStr === "appInfo") {
          this.statistical = this.appInfo_statistical;
        }
        if (showConfStr === "deviceInfo") {
          this.statistical = this.deviceInfo_statistical;
        }
        if (showConfStr === "userInfo") {
          this.statistical = this.userInfo_statistical;
        }
        if (showConfStr === "gatewayInfo") {
          this.statistical = this.gatewayInfo_statistical;
        }
        // 循环当前父组件接收的数据  匹配相对应的中文（拿数据）
        this.statistical.forEach((item, index) => {
          if (this.statisticalValue === item.value) {
            this.oneCartName = item.label;
          }
        });
        break;
      }
    }
    //获取数据
    var metaData = this.cardDataSum[showConfStr];

    this.oneCartValue = metaData[this.statisticalValue.split("Conf")[0]];
    this.rings1();
  },
  methods: {
    allMethods() {
      this.card_1 = this.pageContent;
      // 维度
      var showConfStr = this.card_1.showConf;
      //具体维度下显示内容
      var metaData = this.card_1[showConfStr];
      this.dimensionValue = showConfStr;

      for (const key in metaData) {
        if (metaData[key] == true) {
          //下拉选选中value。用来显示lable
          this.statisticalValue = key;
          if (showConfStr === "appInfo") {
            this.statistical = this.appInfo_statistical;
          }
          if (showConfStr === "deviceInfo") {
            this.statistical = this.deviceInfo_statistical;
          }
          if (showConfStr === "userInfo") {
            this.statistical = this.userInfo_statistical;
          }
          if (showConfStr === "gatewayInfo") {
            this.statistical = this.gatewayInfo_statistical;
          }
          // 循环当前父组件接收的数据  匹配相对应的中文（拿数据）
          this.statistical.forEach((item, index) => {
            if (this.statisticalValue === item.value) {
              this.oneCartName = item.label;
            }
          });
          break;
        }
      }
      //获取数据
      var metaData = this.cardDataSum[showConfStr];

      this.oneCartValue = metaData[this.statisticalValue.split("Conf")[0]];
      this.rings1();
    },
    rings1() {
      var chartDom = document.getElementById("ring1");
      var myChart = echarts.init(this.$refs.agriEcoDev);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },
        //设置中间内容距离div的距离，也就是内边距
        // grid: { // 让图表占满容器
        //   top: "0px",
        //   left: "0px",
        //   right: "0px",
        //   bottom: "0px"
        // }
        color: ["#006cb3"], //圆圈线的颜色
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["70%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              normal: {
                show: false,
                formatter: (params) => {
                  if (params.data.name == "设备在线率") {
                    return params.value + "%" + "\n" + params.name;
                  } else {
                    return params.value + "\n" + params.name;
                  }
                },
                position: "center",
                lineHeight: 25,
                color: "#FFFFFF", //圆圈内容的显示颜色
                fontSize: 17,
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "17", //放大后的效果
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value:
                  this.oneCartValue.length > 9
                    ? this.oneCartValue.slice(0, 9)
                    : this.oneCartValue,
                name: this.oneCartName,
                label: {
                  normal: {
                    show: true,
                  },
                },
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
      // window.addEventListener("resize",function({
      //   myChart.resize()
      // }))
      //  随外层div的大小变化自适应
      let _this = this;
      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.agriEcoDev, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });
      myChart.on("click", (param) => {
        console.log(321)
        console.log(param, "param-------------------------------cartLeft");
        //判断一级 安全监测 名称是否能找到
        let arrName = this.saveAllList.find(
          (item) => item.name == "SecurityMonitor"
        );
        //应用运行次数
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf("RunInfo") != -1) {
              if (param.name == "应用运行次数") {
                this.$router.push({
                  name: "RunInfo",
                  query: {
                    key: index + "",
                    dataDay: this.taDay,
                    starttime: this.startDay,
                    card: true,
                  },
                });
              }
            }
          });
        }

        //应用威胁数
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf("AppAttackMonitor") != -1) {
              if (param.name == "应用威胁数") {
                this.$router.push({
                  name: "AppAttackMonitor",
                  query: {
                    key: index + "",
                    // keyPath: ['2'],
                    dataDay: this.taDay,
                    starttime: this.startDay,
                    card: true,
                  },
                });
              }
            }
          });
        }

        //设备敏感数
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf("AppSensitiveMonitor") != -1) {
              if (param.name == "设备敏感数") {
                this.$router.push({
                  name: "AppSensitiveMonitor",
                  query: {
                    key: index + "",
                    // keyPath: ['3'],
                    dataDay: this.taDay,
                    starttime: this.startDay,
                    card: true,
                  },
                });
              }
            }
          });
        }

        //安全事件数
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf("SafetyEvent") != -1) {
              if (param.name == "安全事件数") {
                this.$router.push({
                  name: "SafetyEvent",
                  query: {
                    key: index + "",
                    // keyPath: ['4'],
                    dataDay: this.taDay,
                    starttime: this.startDay,
                    card: true,
                  },
                });
              }
            }
          });
        }

        //SDK行为数
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf("SDKBehaviour") != -1) {
              if (param.name == "SDK行为数") {
                this.$router.push({
                  name: "SDKBehaviour",
                  query: {
                    key: index + "",
                    // keyPath: ['5'],
                    dataDay: this.taDay,
                    starttime: this.startDay,
                    card: true,
                  },
                });
              }
            }
          });
        }

        //违规总数
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf("ViolationMonitor") != -1) {
              if (param.name == "违规总数") {
                this.$router.push({
                  name: "ViolationMonitor",
                  query: {
                    key: index + "",
                    flag: true,
                    startTime: this.startDay,
                  },
                });
              }
            }
          });
        }
        //"连接设备数"
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf("Accessbehavior") != -1) {
              if (param.name == "连接设备数") {
                this.$router.push({
                  name: "Accessbehavior",
                  query: {
                    key: index + "",
                    flag: true,
                    name: true, //不要删----时间控件以name为判断条件
                    dataDay: this.taDay,
                    starttime: this.startDay,
                    gatewayAddCart: true,
                  },
                });
              }
            }
          });
        }
        //访问应用数
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf("Accessbehavior") != -1) {
              if (param.name == "访问应用数") {
                this.$router.push({
                  name: "Accessbehavior",
                  query: {
                    key: index + "",
                    flag: true,
                    name: true, //不要删----时间控件以name为判断条件
                    dataDay: this.taDay,
                    starttime: this.startDay,
                    gatewayAddCart: true,
                  },
                });
              }
            }
          });
        }
        //连接用户数
        if (arrName.name) {
          arrName.children.forEach((item, index) => {
            if (item.name.indexOf("Accessbehavior") != -1) {
              if (param.name == "连接用户数") {
                this.$router.push({
                  name: "Accessbehavior",
                  query: {
                    key: index + "",
                    flag: true,
                    name: true, //不要删----时间控件以name为判断条件
                    dataDay: this.taDay,
                    starttime: this.startDay,
                    gatewayAddCart: true,
                  },
                });
              }
            }
          });
        }
        //判断一级 设备管理 名称是否能找到
        let phoneName = this.saveAllList.find(
          (item) => item.name == "DeviceManage"
        );
        //  报废设备数
      if (phoneName.name) {
         phoneName.children.forEach((item,index)=>{
           if(item.name.indexOf("Device") !=-1){
            if (param.name == "报废设备数") {
              this.$router.push({
                name: 'Device',
                query: {
                  key: index+'',
                  markValue: '4'
                }
              }) 
            }
           }
         })
      }
      //  丢失设备数
      if (phoneName.name) {
         phoneName.children.forEach((item,index)=>{
           if(item.name.indexOf("Device") !=-1){
            if (param.name == "丢失设备数") {
              this.$router.push({
                name: 'Device',
                query: {
                  key: index+'',
                  markValue: '3'
                }
              }) 
            }
           }
         })
      }
        //  设备在线率
        if (phoneName.name) {
           phoneName.children.forEach((item,index)=>{
             if(item.name.indexOf("Device") !=-1){
              let conValue = ''
                if (param.seriesName == '离线') {
                  conValue = '0'
                } else if (param.seriesName == '在线') {
                  conValue = '1'
                }
                let conStateRate = ''
                if(this.queryCount == '0'){
                  conStateRate = '1'
                }else if(this.queryCount == '1'){
                  conStateRate = '7'
                }else if(this.queryCount == '2'){
                  conStateRate = '30'
                }else if(this.queryCount == '3'){
                  conStateRate = '90'
                }
                if (param.name == "设备在线率") {
                  this.$router.push({
                    name: 'Device',
                    query: {
                      key: index+'',
                      jumpPageName:'SourceHome',//用于跳转后页面判断-------与首页一致先不改
                      conStatus: '1',
                      onlineValue: conStateRate,//当天在线还是离线时间范围
                    }
                  }) 
                }
             }
           })
        }
        //  设备总数
        if (phoneName.name) {
          phoneName.children.forEach((item, index) => {
            if (item.name.indexOf("Device") != -1) {
              if (param.name == "设备总数") {
                this.$router.push({
                  name: "Device",
                  query: {
                    key: index + "",
                    // conStatus: '0'
                  },
                });
              }
            }
          });
        }

        //  离线设备数
        if (phoneName.name) {
          phoneName.children.forEach((item, index) => {
            if (item.name.indexOf("Device") != -1) {
              let conValue = "";
              if (param.seriesName == "离线") {
                conValue = "0";
              } else if (param.seriesName == "在线") {
                conValue = "1";
              }
              let conStateRate = "";
              if (this.queryCount == "0") {
                conStateRate = "1";
              } else if (this.queryCount == "1") {
                conStateRate = "7";
              } else if (this.queryCount == "2") {
                conStateRate = "30";
              } else if (this.queryCount == "3") {
                conStateRate = "90";
              }
              if (param.name == "离线设备数") {
                this.$router.push({
                  name: "Device",
                  query: {
                    key: index + "",
                    jumpPageName: "SourceHome", //用于跳转后页面判断-------与首页一致先不改
                    conStatus: "0",
                    onlineValue: conStateRate, //当天在线还是离线时间范围
                  },
                });
              }
            }
          });
        }

        //  在线设备数
        if (phoneName.name) {
          phoneName.children.forEach((item, index) => {
            if (item.name.indexOf("Device") != -1) {
              let conValue = "";
              if (param.seriesName == "离线") {
                conValue = "0";
              } else if (param.seriesName == "在线") {
                conValue = "1";
              }
              let conStateRate = "";
              if (this.queryCount == "0") {
                conStateRate = "1";
              } else if (this.queryCount == "1") {
                conStateRate = "7";
              } else if (this.queryCount == "2") {
                conStateRate = "30";
              } else if (this.queryCount == "3") {
                conStateRate = "90";
              }
              if (param.name == "在线设备数") {
                this.$router.push({
                  name: "Device",
                  query: {
                    key: index + "",
                    jumpPageName: "SourceHome", //用于跳转后页面判断-------与首页一致先不改
                    conStatus: "1",
                    onlineValue: conStateRate, //当天在线还是离线时间范围
                  },
                });
              }
            }
          });
        }
        //判断一级 用户管理 名称是否能找到
        let userName = this.saveAllList.find(
          (item) => item.name == "UserManage"
        );
        //  用户总数
        if (userName.name) {
          userName.children.forEach((item) => {
            item.children.forEach((i, k) => {
              if (i.name.indexOf("User") != -1) {
                if (param.name == "用户总数") {
                  this.$router.push({
                    name: "User",
                    query: {
                      key: k + "",
                      userFlag: "-1",
                    },
                  });
                }
              }
            });
          });
        }
        //  正常用户数
        if (userName.name) {
          userName.children.forEach((item) => {
            item.children.forEach((i, k) => {
              if (i.name.indexOf("User") != -1) {
                if (param.name == "正常用户数") {
                  this.$router.push({
                    name: "User",
                    query: {
                      key: k + "",
                      userFlag: "1",
                    },
                  });
                }
              }
            });
          });
        }

        //  锁定用户数
        if (userName.name) {
          userName.children.forEach((item) => {
            item.children.forEach((i, k) => {
              if (i.name.indexOf("User") != -1) {
                if (param.name == "锁定用户数") {
                  this.$router.push({
                    name: "User",
                    query: {
                      key: k + "",
                      userFlag: "2",
                    },
                  });
                }
              }
            });
          });
        }

        //  停用用户数
        if (userName.name) {
          userName.children.forEach((item) => {
            item.children.forEach((i, k) => {
              if (i.name.indexOf("User") != -1) {
                if (param.name == "停用用户数") {
                  this.$router.push({
                    name: "User",
                    query: {
                      key: k + "",
                      userFlag: "3",
                    },
                  });
                }
              }
            });
          });
        }
        //  新增用户数
        if (userName.name) {
          userName.children.forEach((item) => {
            item.children.forEach((i, k) => {
              if (i.name.indexOf("User") != -1) {
                if (param.name == "新增用户数") {
                  this.$router.push({
                    name: "User",
                    query: {
                      key: k + "",
                      userFlag: "0",
                    },
                  });
                }
              }
            });
          });
        }
      });

      //全局设置Echrts - 根据窗口的大小变动图表
      // 单个图可用  多个  只对最后一个有效
      // window.onresize = function () {
      //   myChart.resize();
      // }
    },
    // 默认显示当天时间
    getdatatime() {
      //默认显示今天
      return new Date().getTime();
    },
    //xxxx-xx-xx
    UTCformat(utc) {
      var date = new Date(utc),
        year = date.getFullYear(),
        month =
          date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : "0" + parseInt(date.getMonth() + 1),
        day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate(),
        hour = date.getHours() > 9 ? date.getHours() : "0" + date.getHours(),
        minutes =
          date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes(),
        seconds =
          date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
      var res = year + "-" + month + "-" + day + " ";
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
// 齿轮设置
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style> 