<template>
  <div style="width: 100%; height: 100%">
    <div style="width: 100%; height: 100%">
      <div class="echartsGroup" style="width: 100%; height: 100%; position: relative;" v-show="mapFlag">
        <!-- 返回中国地图 -->
        <div class="button" v-show="isReturnChina" @click="returnChinaFn()">
          <i class="el-icon-back" style="font-size:20px;"></i>
        </div>
        <div id="map" ref="map" style="width: 100%; height: 100%"></div>
      </div>

      <div v-show="headerFlag" style="width: 100%; height: 100%; position: relative">
        <div class="headerTu" style="
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
          ">
          <div class="square1" style="
              width: 20%;
              display: flex;
              flex-direction: column;
              margin: auto;
            ">
            <div class="borderlef1" style="width: 150px; height: 80px; margin-top: -76%">
              <!-- title="违规行为" -->
              <el-popover style="color: aliceblue" effect="Light" placement="top-start" width="200" trigger="hover"
                popper-class="wrap" :content="
                  '违规行为' +
                  '【' +
                  this.heGuiStatusIndex +
                  '】' +
                  '：' +
                  this.heGuiStatus +
                  '人'
                ">
                <dv-border-box-10 :color="['#123D8C', '#123D8C']" slot="reference" style="
                    width: 180px;
                    height: 60px;
                    border: white;
                    text-align: center;
                    line-height: 60px;
                  "><span style="color: aliceblue">合规状态</span></dv-border-box-10>
              </el-popover>
            </div>
            <div class="borderlef2" style="width: 150px; height: 80px; margin-top: 35%">
              <!-- title="用户活跃" -->
              <el-popover placement="top-start" width="200" trigger="hover" :content="
                '用户活跃' +
                '【' +
                this.maxUserDataIndex +
                '】' + '：' +
                this.maxUserData +
                '人'
              " popper-class="wrap">
                <dv-border-box-10 :color="['#123D8C', '#123D8C']" slot="reference" style="
                    width: 180px;
                    height: 60px;
                    border: white;
                    text-align: center;
                    line-height: 60px;
                  "><span style="color: aliceblue">作息偏好</span></dv-border-box-10>
              </el-popover>
            </div>
          </div>
          <div style="
              width: 20%;
              display: flex;
              flex-direction: column;
              margin: auto;
            "></div>
          <div class="square2" style="
              width: 20%;
              display: flex;
              flex-direction: column;
              margin: auto;
            ">
            <div class="borderlef3" style="width: 150px; height: 80px; margin-top: -76%">
              <!-- title="敏感词" -->
              <el-popover placement="top-start" width="200" trigger="hover" popper-class="wrap" :content="
                '敏感词' +
                '【' +
                this.maxWordDataIndex +
                '】' +
                '：' +
                this.maxWordData +
                '人'
              ">
                <dv-border-box-10 :color="['#123D8C', '#123D8C']" slot="reference" style="
                    width: 180px;
                    height: 60px;
                    border: white;
                    text-align: center;
                    line-height: 60px;
                  "><span style="color: aliceblue">言论特征</span></dv-border-box-10>
              </el-popover>
            </div>
            <div class="borderlef4" style="width: 150px; height: 80px; margin-top: 35%">
              <!-- title="用户使用" -->
              <el-popover placement="top-start" width="200" trigger="hover" popper-class="wrap" :content="
                '应用名称' +
                '【' +
                this.maxUseDataIndex +
                '】' +
                '：' +
                this.maxUseData +
                '人'
              ">
                <dv-border-box-10 :color="['#123D8C', '#123D8C']" slot="reference" style="
                    width: 180px;
                    height: 60px;
                    border: white;
                    text-align: center;
                    line-height: 60px;
                  "><span style="color: aliceblue">上网偏好</span></dv-border-box-10>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
      
      
    </div>
    <!-- <p style="color:#fff;">{{mapData.appInfo.alarmInfo}}</p> -->
  </div>
</template>

<script>
import * as echarts from "echarts"; //echarts图表引用
import "../../../assets/iconfont/iconfont.js"; //小齿轮设置的icon
import elementResizeDetectorMaker from "element-resize-detector"; //获取div宽高
import "echarts/map/js/china"; //中国地图引用js
import moment from "moment";
// import cityData from '@/utils/city';//城市模拟数据
import geoCoordMap from "../../../utils/cityOption"; //城市的经纬度
import 'echarts/map/js/china.js' // 核心文件
require('echarts/theme/macarons') //
//下边这两行尤为重要，数据才能正常渲染
import china from 'echarts/map/json/china.json'
echarts.registerMap('china', china)
export default {
  props: {
    pageContent: {
      type: Object,
      default: () => { },
    },
    //地图
    mapData: {
      type: Object,
      default: () => { },
    },
    queryCount: {
      type: String,
    },
  },
  data() {
    return {
      strNumber:0,
      queryName:'',
      dataRes:[],
      flagCity:false,
      cityOption:[],
      newArr2:[],
      newArr:[],
      province_city:null,
      cityName:[],
      provinceData: [  //省份公司的数据
        { name: '深圳市', value: 23, children: ['福田', '南山', '龙华'] },
      ],
      provinces: {   //数据
        台湾: 'taiwan',
        河北: 'hebei',
        山西: 'shanxi',
        辽宁: 'liaoning',
        吉林: 'jilin',
        黑龙江: 'heilongjiang',
        江苏: 'jiangsu',
        浙江: 'zhejiang',
        安徽: 'anhui',
        福建: 'fujian',
        江西: 'jiangxi',
        山东: 'shandong',
        河南: 'henan',
        湖北: 'hubei',
        湖南: 'hunan',
        广东: 'guangdong',
        海南: 'hainan',
        四川: 'sichuan',
        贵州: 'guizhou',
        云南: 'yunnan',
        陕西: 'shanxi1',
        甘肃: 'gansu',
        青海: 'qinghai',
        新疆: 'xinjiang',
        广西: 'guangxi',
        内蒙古: 'neimenggu',
        宁夏: 'ningxia',
        西藏: 'xizang',
        北京: 'beijing',
        天津: 'tianjin',
        上海: 'shanghai',
        重庆: 'chongqing',
        香港: 'xianggang',
        澳门: 'aomen'
      },
      isReturnChina: false, //是否显示返回中国地图
      options: null,   //echarts 存数据
      saveAllList: [],
      taDay: "", //结束时间--（当天时间）
      startDay: "", //开始时间
      queryObj: "", //卡片的数据
      cityTitle: "", //地图悬浮框提示
      metaMapData: null,
      heGuiStatus: "", //合规状态最大值
      heGuiStatusIndex: "", //合规状态最大值名称
      maxUserData: "", //作息偏好最大值
      maxUserDataIndex: "", //作息偏好最大值名称
      maxWordData: "", //敏感词最大值
      maxWordDataIndex: "", //敏感词最大值名称
      maxUseData: "", //上网偏好
      maxUseDataIndex: "", //上网偏好最大值名称
      // sumData:{},
      mapFlag: false, //地图默认显示
      headerFlag: false, //人头默认隐藏
      // city:cityData,//城市 模拟数据
      location: geoCoordMap, //城市经纬度
    };
  },
  watch: {
    pageContent: {
      handler: function (newVal, oldVal) {
        this.pageContent = newVal;
        var showConfStr = this.pageContent.showConf;
        if (showConfStr === "appInfo" || showConfStr === "deviceInfo") {
          (this.mapFlag = true), (this.headerFlag = false), this.getMapChart('china',{data:[]});
        }
        if (showConfStr === "userInfo") {
          (this.mapFlag = false), (this.headerFlag = true);
        }
      },
      deep: true,
      // immediate: true,
    },
    mapData: {
      handler: function (newVal, oldVal) { 
        // //这修改省份名称
        // for(var k in newVal.appInfo.alarmInfo){
        //   if ("北京市" === newVal.appInfo.alarmInfo[k].province) {
        //     newVal.appInfo.alarmInfo[k].province = "北京";
        //    }
        // }
        this.metaMapData = newVal;
        this.strNumber = 0
        this.queryName = ''
        this.getMapChart('china',{data:[]});
      },
      deep: true,
    },
    queryCount: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.queryObj = newVal;
          this.chartsData();
          this.getMapChart('china',{data:[]});
          var taDay = "";
          var startDay = "";
          if (0 == newVal) {
            taDay = this.UTCformat(this.getdatatime());
            startDay = taDay;
          } else if (1 == newVal) {
            taDay = this.UTCformat(this.getdatatime());
            startDay = moment().subtract(6, "days").format("YYYY-MM-DD");
          } else if (2 == newVal) {
            taDay = this.UTCformat(this.getdatatime());
            startDay = moment().subtract(29, "days").format("YYYY-MM-DD");
          } else if (3 == newVal) {
            taDay = this.UTCformat(this.getdatatime());
            startDay = moment().subtract(89, "days").format("YYYY-MM-DD");
          } else {
            console.log("参数有误");
          }
          this.taDay = taDay;
          this.startDay = startDay;
        }
      },
      deep: true,
    },
  },
  //获取数据
  created() {
    this.queryObj = this.queryCount;
    this.chartsData();
    // 维度
    var showConfStr = this.pageContent.showConf;
    this.dimensionValue = showConfStr;
    //具体维度下显示内容
    var metaData = this.pageContent[showConfStr];
    this.statisticalValue1 = metaData;
    if (showConfStr === "appInfo") {
      this.cityTitle = "应用威胁次数";
      this.statistical = this.appInfo_statistical;
      this.chartsTu = this.appChart;
      this.chartsValue = "map";
      this.metaMapData = this.mapData[showConfStr].alarmInfo;
    }
    if (showConfStr === "deviceInfo") {
      this.cityTitle = "设备违规次数";
      this.statistical = this.deviceInfo_statistical;
      this.chartsTu = this.appChart;
      this.chartsValue = "map";
      this.metaMapData = this.mapData[showConfStr].violaInfo;
    }
    if (showConfStr === "userInfo") {
      this.statistical = this.userInfo_statistical;
      this.chartsTu = this.userChart;
      this.chartsValue = "";
      this.metaMapData = this.mapData[showConfStr].complianceStatus;
    }

    var newVal = this.queryCount;
    var taDay = "";
    var startDay = "";
    if (0 == newVal) {
      taDay = this.UTCformat(this.getdatatime());
      startDay = taDay;
    } else if (1 == newVal) {
      taDay = this.UTCformat(this.getdatatime());
      startDay = moment().subtract(6, "days").format("YYYY-MM-DD");
    } else if (2 == newVal) {
      taDay = this.UTCformat(this.getdatatime());
      startDay = moment().subtract(29, "days").format("YYYY-MM-DD");
    } else if (3 == newVal) {
      taDay = this.UTCformat(this.getdatatime());
      startDay = moment().subtract(89, "days").format("YYYY-MM-DD");
    } else {
      console.log("参数有误");
    }
    this.taDay = taDay;
    this.startDay = startDay;
  },
  mounted() { 
    window.clickRoute = this.clickRoute;
    this.$nextTick(() => {
     this.getMapChart('china',{data:[]})
    }) 
    this.saveAllList = [...this.$store.state.selectList]
    var showConfStr = this.pageContent.showConf;
    if (showConfStr === "appInfo") {
      this.cityTitle = "应用威胁次数";
      (this.mapFlag = true), (this.headerFlag = false);
      this.getMapChart('',{data:[]});
    } 
    if (showConfStr === "deviceInfo") {
      this.cityTitle = "设备违规次数";
      (this.mapFlag = true), (this.headerFlag = false);
      this.getMapChart('',{data:[]});
    }
    if (showConfStr === "userInfo") {
      (this.mapFlag = false), (this.headerFlag = true);
    }
  },
  methods: {
    // 返回中国地图
    returnChinaFn() {
      this.queryName = ''
      this.strNumber = 0
      this.$emit('nameCity',{name:""})
      this.getMapChart('china',{data:[]});
    },
    // 默认显示当天时间
    getdatatime() {
      //默认显示今天
      return new Date().getTime();
    },
    //xxxx-xx-xx
    UTCformat(utc) {
      var date = new Date(utc),
        year = date.getFullYear(),
        month =
          date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : "0" + parseInt(date.getMonth() + 1),
        day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate(),
        hour = date.getHours() > 9 ? date.getHours() : "0" + date.getHours(),
        minutes =
          date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes(),
        seconds =
          date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
      var res = year + "-" + month + "-" + day + " ";
      return res;
    },

    //图形数据接口
    async chartsData() {
      var param = {
        timeType: this.queryObj,
      };
      const res = await this.$axios.post(
        "/httpServe/securitySituation/getChartDataInfo",
        param,
        true
      );
      // 合规状态
      var arr = this.mapData.userInfo.num;
      var max = arr[0];
      var index = 0;
      for (var i = 1; i < arr.length; i++) {
        if (max < arr[i]) {
          max = arr[i];
          index = i;
        }
      }
      this.heGuiStatus = max == undefined ? 0 : max;

      // this.heGuiStatusIndex 
      this.heGuiStatusIndex = this.mapData.userInfo.name[index] === undefined ? '无' : this.mapData.userInfo.name[index];
      if (this.heGuiStatusIndex == '0') {
        this.heGuiStatusIndex = '设备越狱'
      } else if (this.heGuiStatusIndex == '1') {
        this.heGuiStatusIndex = '系统版本'
      } else if (this.heGuiStatusIndex == '2') {
        this.heGuiStatusIndex = '应用黑名单'
      } else if (this.heGuiStatusIndex == '3') {
        this.heGuiStatusIndex = '应用白名单'
      } else if (this.heGuiStatusIndex == '4') {
        this.heGuiStatusIndex = ''
      } else if (this.heGuiStatusIndex == '5') {
        this.heGuiStatusIndex = '地理围栏'
      } else if (this.heGuiStatusIndex == '6') {
        this.heGuiStatusIndex = ''
      } else if (this.heGuiStatusIndex == '7') {
        this.heGuiStatusIndex = '流量异常'
      } else if (this.heGuiStatusIndex == '8') {
        this.heGuiStatusIndex = 'SIM卡变更'
      } else if (this.heGuiStatusIndex == '9') {
        this.heGuiStatusIndex = '违规外联'
      } else if (this.heGuiStatusIndex == '10') {
        this.heGuiStatusIndex = '设备管理器未激活'
      } else if (this.heGuiStatusIndex == '11') {
        this.heGuiStatusIndex = '证书变更'
      } else if (this.heGuiStatusIndex == '12') {
        this.heGuiStatusIndex = '必装应用'
      } else if (this.heGuiStatusIndex == '13') {
        this.heGuiStatusIndex = 'SD卡变更'
      } else if (this.heGuiStatusIndex == '14') {
        this.heGuiStatusIndex = '设备电量'
      } else if (this.heGuiStatusIndex == '15') {
        this.heGuiStatusIndex = '存储空间'
      } else if (this.heGuiStatusIndex == '16') {
        this.heGuiStatusIndex = '系统版本'
      } else if (this.heGuiStatusIndex == '22') {
        this.heGuiStatusIndex = '应用黑白名单'
      } else if (this.heGuiStatusIndex == '99') {
        this.heGuiStatusIndex = '其他违规'
      } else if (this.heGuiStatusIndex == '33') {
        this.heGuiStatusIndex = 'WIFI黑白名单'
      } else if (this.heGuiStatusIndex == '36') {
        this.heGuiStatusIndex = '系统功能'
      }

      // 作息偏好
      if(res.data == null){
        res.data={
          userInfo : {
            userActiveTrend:{
              name : [],
              num : []
            }
          
        }}
      }
      var sleepArr = res.data.userInfo.userActiveTrend.num;
      var sleepMax = sleepArr[0];
      var sleepIndex = 0;
      for (var i = 1; i < sleepArr.length; i++) {
        if (sleepMax < sleepArr[i]) {
          sleepMax = sleepArr[i];
          sleepIndex = i;
        }
      }
      this.maxUserData = sleepMax;
      this.maxUserDataIndex = res.data.userInfo.userActiveTrend.name[sleepIndex];

      if (
        this.maxUserDataIndex == "00" ||
        this.maxUserDataIndex == "01" ||
        this.maxUserDataIndex == "02" ||
        this.maxUserDataIndex == "03" ||
        this.maxUserDataIndex == "04" ||
        this.maxUserDataIndex == "05" ||
        this.maxUserDataIndex == "06" ||
        this.maxUserDataIndex == "07" ||
        this.maxUserDataIndex == "08" ||
        this.maxUserDataIndex == "09" ||
        this.maxUserDataIndex == "10" ||
        this.maxUserDataIndex == "11" ||
        this.maxUserDataIndex == "12" ||
        this.maxUserDataIndex == "13" ||
        this.maxUserDataIndex == "14" ||
        this.maxUserDataIndex == "15" ||
        this.maxUserDataIndex == "16" ||
        this.maxUserDataIndex == "17" ||
        this.maxUserDataIndex == "18" ||
        this.maxUserDataIndex == "19" ||
        this.maxUserDataIndex == "20" ||
        this.maxUserDataIndex == "21" ||
        this.maxUserDataIndex == "22" ||
        this.maxUserDataIndex == "23" ||
        this.maxUserDataIndex == "24"
      ) {
        this.maxUserDataIndex = this.UTCformat(this.getdatatime())
      }
      else {
        this.maxUserDataIndex = this.maxUserDataIndex
      }
      // var userData =  res.data.userInfo.userActiveTrend.num
      // this.maxUserData = Math.max(...userData)

      // 言论特征  敏感词
      if(res.data.userInfo.sensitiveWords.num){
      var wordArr = res.data.userInfo.sensitiveWords.num;
      var wordMax = wordArr[0];
      var wordIndex = 0;
      for (var i = 1; i < wordArr.length; i++) {
        if (wordMax < wordArr[i]) {
          wordMax = wordArr[i];
          wordIndex = i;
        }
      }
      this.maxWordData = wordMax === undefined ? 0 : wordMax;
      this.maxWordDataIndex =
        res.data.userInfo.sensitiveWords.name[wordIndex] === undefined
          ? "无"
          : res.data.userInfo.sensitiveWords.name[wordIndex];
    }
      // var wordData =  res.data.userInfo.sensitiveWords.num
      // this.maxWordData =wordData.length ===0?0:Math.max(...wordData)

      // 上网偏好
      var goodArr = res.data.userInfo.useAppTop.num;
      var goodMax = goodArr[0];
      var goodIndex = 0;
      for (var i = 1; i < goodArr.length; i++) {
        if (goodMax < goodArr[i]) {
          goodMax = goodArr[i];
          goodIndex = i;
        }
      }
      this.maxUseData = goodMax === undefined ? 0 : goodMax;
      this.maxUseDataIndex =
        res.data.userInfo.useAppTop.name[goodIndex] === undefined
          ? "无"
          : res.data.userInfo.useAppTop.name[goodIndex];

      // var useData =  res.data.userInfo.useAppTop.num
      // this.maxUseData =useData.length ===0?0:Math.max(...useData)
    },
    conversion(typeData) {
      //中文描述
      var describe;

      switch (typeData) {
        case "pieChart":
          describe = "饼图";
          break;
        case "ringMoreChart":
          describe = "环形图";
          break;
        case "rosesChart":
          describe = "玫瑰图";
          break;
        case "topChart":
          describe = "柱状图";
          break;
        case "lineChart":
          describe = "折线图";
          break;
        case "cloudChart":
          describe = "词云图";
          break;
        case "barTuChart":
          describe = "条形图";
          break;
        default:
      }

      return describe;
    },
    // 中国地图点击省份 显示当前省份的详细的地区。
    chinaMapHidden(myChart) {
      let that = this
      myChart.off('click')//这里解决多次触发点击事件 但是还会执行2次  引用echarts地图，点击各个省份时，点击一个调用两次接口，再点击一次，调用四次接口，再点击调用八次。。。。依次叠加，问题在于，没有将地图上的点击事件清空
      myChart.on('click', async function (params) {
        if(params.data) {
          that.queryName+=params.data.name+','
          that.strNumber++
        }
        that.queryName+=params.data?params.data.name+',':""
        that.strNumber++
        if(that.strNumber >= 2){
          that.strNumber = 2
          let arrName = that.saveAllList.find(item=>item.name=="SecurityMonitor")
          if(!params.data || !params.data.name){
            return false
          }
          //    应用威胁分布 跳转  安全检测下的  ---   “威胁行为” 
          if(arrName.name){
            arrName.children.forEach((item,index)=>{
              if(item.name.indexOf("AppAttackMonitor") !=-1){
                if(that.dimensionValue === 'appInfo'){
                  
                    that.$router.push({
                      name: 'AppAttackMonitor',
                      query: {
                        key: index+'',
                        // keyPath: ["2"],
                        dataDay: that.taDay,
                        starttime: that.startDay,
                        cli: true,
                        falgKeypath: true,
                        cityName:that.queryName
                      },
                    })
                  
                }
              }
            }) 
          }
          //  设备违规分布 跳转  安全检测下的  ---   “违规行为”
          if(arrName.name){
            arrName.children.forEach((item,index)=>{
              if(item.name.indexOf("ViolationMonitor") !=-1){
                if(that.dimensionValue === 'deviceInfo'){
                    that.$router.push({
                      name: 'ViolationMonitor',
                      query: {
                        key: index+'',
                        // keyPath: ["2"],
                        dataDay: that.taDay,
                        starttime: that.startDay,
                        cli: true,
                        falgKeypath: true,
                        cityName:that.queryName
                      },
                    })
                }
              }
            }) 
          }
        }
        that.flagCity = false
        if (params.name in that.provinces) {
          let s = await import(`echarts/map/js/province/${that.provinces[params.name]}.js`);
          if (s) {
            that.forEachMap(params)
            setTimeout(()=>{
              that.getMapChart(params.name,that.dataRes)
            },100)
            
            
          }
        }
      })
    },
    forEachMap(params){
      const that = this
      let cityName = ""
        if (params.name == '北京') {
          cityName = "北京市";
        }else if (params.name == '天津') {
          cityName = "天津市";
        } else if (params.name == '上海' ) {
          cityName = "上海市";
        } else if (params.name == '重庆' ) {
          cityName = "重庆市";
        } else if (params.name == '河北' ) {
          cityName = "河北省";
        } else if (params.name == '山西' ) {
          cityName = "山西省";
        } else if (params.name == '辽宁' ) {
          cityName = "辽宁省";
        } else if (params.name == '江苏' ) {
          cityName = "江苏省";
        } else if (params.name == '浙江' ) {
          cityName = "浙江省";
        } else if (params.name == '安徽' ) {
          cityName = "安徽省";
        } else if (params.name == '福建') {
          cityName = "福建省";
        } else if (params.name == '江西') {
          cityName = "江西省";
        } else if (params.name == '山东') {
          cityName = "山东省";
        } else if (params.name == '河南') {
          cityName = "河南省";
        } else if (params.name == '湖北') {
          cityName = "湖北省";
        } else if (params.name == '湖南') {
          cityName = "湖南省";
        } else if (params.name == '海南') {
          cityName = "海南省";
        } else if (params.name == '四川') {
          cityName = "四川省";
        } else if (params.name == '贵州') {
          cityName = "贵州省";
        } else if (params.name == '云南') {
          cityName = "云南省";
        }
        else if (params.name=== '陕西') {
          cityName = '陕西省'
        } else if (params.name === '黑龙江') {
          cityName = '黑龙江省'
        } else if (params.name === '澳门') {
          cityName = '澳门特别行政区'
        }
        else if (params.name === '香港') {
          cityName = '香港特别行政区，香港特别行政区，中西区'
        }
        else if (params.name === '香港') {
          cityName = '香港特别行政区'
        }
        else if (params.name === '新疆') {
          cityName = "新疆维吾尔自治区";
        } else if (params.name === '西藏') {
          cityName = "西藏自治区";
        } else if (params.name === '广西') {
          cityName = "广西壮族自治区";
        } else if (params.name === '境外') {
          cityName = "境外";
        } else if (params.name === '内蒙古') {
          cityName = "内蒙古自治区";
        } else if (params.name === '宁夏') {
          cityName = "宁夏回族自治区";
        } else if (params.name === '吉林') {
          cityName = "吉林省";
        } else if (params.name === '广东') {
          cityName = "广东省";
        }
        else if (params.name === '甘肃') {
         cityName = "甘肃省";
        } else if (params.name === '湖南') {
          cityName = "湖南省";
        } else if (params.name === '青海') {
          cityName = "青海省";
        } else if (params.name === '台湾') {
          cityName = "台湾省";
        }
      
      that.cityOption = that.province_city.get(cityName)
      //所有省市区坐标轴 后期没事优化请抽离出来封装成js文件抛出，其他没有删除
      var geoCoordMap = {
        北京市:[116.46,39.92],
        北京:[116.46,39.92],
        平谷区:[117.1,40.13],
        密云区:[116.85,40.37],
        顺义区:[116.65,40.13],
        通州区:[116.66,39.91],
        怀柔区:[116.62,40.32],
        大兴区:[116.33,39.73],
        房山区:[115.98,39.72],
        延庆区:[115.97,40.47],
        昌平区:[116.2,40.22],
        海淀区:[116.31,39.96],
        朝阳区:[116.49,39.92],
        石景山区:[116.19,39.91],
        西城区:[116.37,39.91],
        东城区:[116.41,39.92],
        丰台区:[116.29,39.86],
        门头沟区:[116.11,39.94],
        上海市:[121.48,31.22],
        上海:[121.48,31.22],
        嘉定区:[121.25,31.38],
        宝山区:[121.49,31.40],
        奉贤区:[121.46,30.92],
        松江区:[121.24,31],
        金山区:[121.16,30.89],
        青浦区:[121.11,31.15],
        崇明区:[121.40,31.63],
        浦东新区:[121.57,31.25],
        普陀区:[121.39,31.24],
        静安区:[121.45,31.23],
        虹口区:[121.49,31.26],
        杨浦区:[121.52,31.27],
        黄浦区:[121.49,31.22],
        长宁区:[121.42,31.22],
        徐汇区:[121.44,31.18],
        闵行区:[121.38,31.11],
        天津市:[117.2,39.13],
        天津:[117.2,39.13],
        宁河区:[117.83,39.33],
        蓟州区:[117.41,40.05],
        宝坻区:[117.31,39.72],
        武清区:[117.06,39.37],
        北辰区:[117.13,39.23],
        红桥区:[117.16,39.18],
        河北区:[117.20,39.16],
        南开区:[117.16,39.12],
        和平区:[117.19,39.12],
        河东区:[117.23,39.12],
        河西区:[117.22,39.10],
        东丽区:[117.31,39.09],
        西青区:[117.01,39.14],
        津南区:[117.38,38.99],
        滨海新区:[117.65,39.03],
        静海区:[116.93,38.94],
        重庆市:[106.54,29.59],
        重庆:[106.54,29.59],
        綦江区:[106.56,29.41],
        长寿区:[106.64,29.01],
        南桐区:[107.04,29.86],
        合川区:[106.28,29.26],
        潼南区:[106.22,30.03],
        铜梁区:[105.8,30.16],
        壁山区:[106.03,29.86],
        荣昌区:[106.21,29.62],
        大足区:[105.59,29.4],
        永川区:[105.71,29.75],
        万盛区:[105.91,29.38],
        城口县:[108.6649,31.946293],
        巫溪县:[109.628912,31.3966],
        巫山县:[109.878928,31.074843],
        奉节县:[109.465774,31.019967],
        云阳县:[108.697698,30.930529],
        开州区:[108.413317,31.167735],
        万州区:[108.380246,30.807807],
        梁平区:[107.800034,30.672168],
        忠县:[108.037518,30.291537],
        垫江县:[107.348692,30.330012],
        石柱土家族自治县:[108.112448,29.99853],
        黔江区:[108.782577,29.527548],
        酉阳土家族苗族自治县:[108.767201,28.839828],
        秀山土家族苗族自治县:[108.996043,28.444772],
        彭水苗族土家族自治县:[108.166551,29.293856],
        武隆区:[107.75655,29.32376],
        涪陵区:[107.394905,29.703652],
        南川区:[107.098153,29.156646],
        大渡口区:[106.48613,29.481002],
        北碚区:[106.437868,29.82543],
        沙坪坝区:[106.4542,29.541224],
        九龙坡区:[106.480989,29.523492],
        渝北区:[106.512851,29.601451],
        南川区:[107.098153,29.156646],
        江津区:[106.253156,29.283387],
        江北区:[106.532844,29.575352],
        南岸区:[106.560813,29.523992],
        巴南区:[106.519423,29.381919],
        渝中区:[106.56288,29.556742],
        赤峰市:[118.956806,42.275317],
        西藏自治区:[91.11,29.97],
        拉萨市:[91.132212,29.660361],
        阿里地区:[80.105498,32.503187],
        山南市:[91.766529,29.236023],
        昌都市:[97.178452,31.136875],
        日喀则市:[88.885148,29.267519],
        那曲市:[92.060214,31.476004],
        林芝市:[94.362348,29.654693],
        新疆维吾尔自治区:[87.68,43.77],
        乌鲁木齐市:[87.617733,43.792818],
        克拉玛依市:[84.873946,45.595886],
        吐鲁番市:[89.184078,42.947613],
        哈密市:[93.51316,42.833248],
        昌吉回族自治州:[87.304012,44.014577],
        博尔塔拉蒙古自治州:[82.074778,44.903258],
        巴音郭楞蒙古自治州:[86.150969,41.768552],
        阿克苏地区:[80.265068,41.170712],
        克孜勒苏柯尔克孜自治州:[76.172825,39.713431],
        喀什地区:[75.989138,39.467664],
        和田地区:[79.92533,37.110687],
        伊犁哈萨克自治州:[81.317946,43.92186],
        塔城地区:[82.985732,46.746301],
        阿勒泰地区:[88.13963,47.848393],
        石河子市:[86.041075,44.305886],
        阿拉尔市:[81.285884,40.541914],
        图木舒克市:[79.077978,39.867316],
        五家渠市:[87.526884,44.167401],
        北屯市:[87.824932,47.353177],
        铁门关市:[85.501218,41.827251],
        双河市:[82.353656,44.840524],
        可克达拉市:[80.63579,43.6832],
        昆玉市:[79.287372,37.207994],
        胡杨河市:[84.8275959,44.69288853],
        甘肃省:[103.73,36.03],
        兰州市:[103.823557,36.058039],
        临夏回族自治州:[103.212006,35.599446],
        金昌市:[102.187888,38.514238],
        张掖市:[100.455472,38.932897],
        酒泉市:[98.510795,39.744023],
        定西市:[104.626294,35.579578],
        甘南藏族自治州:[102.911008,34.986354],
        白银市:[104.173606,36.54568],
        陇南市:[104.929379,33.388598],
        天水市:[105.724998,34.578529],
        庆阳市:[107.638372,35.734218],
        嘉峪关市:[98.277304,39.786529],
        武威市:[102.634697,37.929996],
        平凉市:[106.684691,35.54279],
        陕西省:[108.95,34.27],
        西安市:[108.948024,34.263161],
        汉中市:[107.028621,33.077668],
        咸阳市:[108.705117,34.333439],
        榆林市:[109.741193,38.290162],
        安康市:[109.029273,32.6903],
        延安市:[109.49081,36.596537],
        宝鸡市:[107.14487,34.369315],
        商洛市:[109.939776,33.868319],
        渭南市:[109.502882,34.499381],
        铜川市:[108.979608,34.916582],
        四川省:[104.06,30.67],
        成都市:[104.065735,30.659462],
        自贡市:[104.773447,29.352765],
        攀枝花市:[101.716007,26.580446],
        泸州市:[105.443348,28.889138],
        德阳市:[104.398651,31.127991],
        绵阳市:[104.741722,31.46402],
        广元市:[105.829757,32.433668],
        遂宁市:[105.571331,30.513311],
        内江市:[105.066138,29.58708],
        乐山市:[103.761263,29.582024],
        南充市:[106.082974,30.795281],
        眉山市:[103.831788,30.048318],
        宜宾市:[104.630825,28.760189],
        广安市:[106.633369,30.456398],
        达州市:[107.502262,31.209484],
        雅安市:[103.001033,29.987722],
        巴中市:[106.753669,31.858809],
        资阳市:[104.641917,30.122211],
        阿坝藏族羌族自治州:[102.221374,31.899792],
        甘孜藏族自治州:[101.963815,30.050663],
        凉山彝族自治州:[102.258746,27.886762],
        广西壮族自治区:[108.33,22.84],
        南宁市:[108.320004,22.82402],
        柳州市:[109.411703,24.314617],
        桂林市:[110.299121,25.274215],
        梧州市:[111.297604,23.474803],
        北海市:[109.119254,21.473343],
        防城港市:[108.345478,21.614631],
        钦州市:[108.624175,21.967127],
        贵港市:[109.602146,23.0936],
        玉林市:[110.154393,22.63136],
        百色市:[106.616285,23.897742],
        贺州市:[111.552056,24.414141],
        河池市:[108.062105,24.695899],
        来宾市:[109.229772,23.733766],
        崇左市:[107.353926,22.404108],
        河南省:[113.65,34.76],
        郑州市:[113.665412,34.757975],
        开封市:[114.341447,34.797049],
        洛阳市:[112.434468,34.663041],
        平顶山市:[113.307718,33.735241],
        安阳市:[114.352482,36.103442],
        鹤壁市:[114.295444,35.748236],
        新乡市:[113.883991,35.302616],
        焦作市:[113.238266,35.23904],
        濮阳市:[115.041299,35.768234],
        许昌市:[113.826063,34.022956],
        漯河市:[114.026405,33.575855],
        三门峡市:[111.194099,34.777338],
        南阳市:[112.540918,32.999082],
        商丘市:[115.650497,34.437054],
        信阳市:[114.075031,32.123274],
        周口市:[114.649653,33.620357],
        驻马店市:[114.024736,32.980169],
        济源市:[112.590047,35.090378],
        // 内蒙古
        内蒙古自治区:[111.65,40.82],
        赤峰市: [118.956806, 42.275317],
        呼伦贝尔市: [119.758168, 49.215333],
        兴安盟: [122.070317, 46.076268],
        呼和浩特市: [111.670801, 40.818311],
        包头市: [109.840405, 40.658168],
        乌海市: [106.825563, 39.673734],
        通辽市: [122.263119, 43.617429],
        鄂尔多斯市: [109.99029, 39.817179],
        巴彦淖尔市: [107.416959, 40.757402],
        乌兰察布市: [113.114543, 41.034126],
        锡林郭勒盟: [116.090996, 43.944018],
        阿拉善盟: [105.706422, 38.844814],
        // 吉林省
        吉林省:[125.35,43.88],
        长春市: [125.3245, 43.886841],
        吉林市: [126.55302, 43.843577],
        四平市: [124.370785, 43.170344],
        辽源市: [125.145349, 42.902692],
        通化市: [125.936501, 41.721177],
        白山市: [126.427839, 41.942505],
        松原市: [124.823608, 45.118243],
        白城市: [122.841114, 45.619026],
        延边朝鲜族自治州: [129.513228, 42.904823],
        // 浙江省
        浙江省:[120.19,30.26],
        杭州市: [120.153576, 30.287459],
        宁波市: [121.549792, 29.868388],
        温州市: [120.672111, 28.000575],
        嘉兴市: [120.750865, 30.762653],
        湖州市: [120.102398, 30.867198],
        绍兴市: [120.582112, 29.997117],
        金华市: [119.649506, 29.089524],
        衢州市: [118.87263, 28.941708],
        舟山市: [122.106863, 30.016028],
        台州市: [121.428599, 28.661378],
        丽水市: [119.921786, 28.451993],
        // 江西
        江西省:[115.89,28.68],
        南昌市: [115.892151, 28.676493],
        景德镇市: [117.214664, 29.29256],
        萍乡市: [113.852186, 27.622946],
        九江市: [115.992811, 29.712034],
        新余市: [114.930835, 27.810834],
        鹰潭市: [117.033838, 28.238638],
        赣州市: [114.940278, 25.85097],
        吉安市: [114.986373, 27.111699],
        宜春市: [114.391136, 27.8043],
        抚州市: [116.358351, 27.98385],
        上饶市: [117.971185, 28.44442],
        // 湖北
        湖北省:[114.31,30.52],
        武汉市: [114.298572, 30.584355],
        黄石市: [115.077048, 30.220074],
        十堰市: [110.787916, 32.646907],
        宜昌市: [111.290843, 30.702636],
        襄阳市: [112.144146, 32.042426],
        鄂州市: [114.890593, 30.396536],
        荆门市: [112.204251, 31.03542],
        孝感市: [113.926655, 30.926423],
        荆州市: [112.23813, 30.326857],
        黄冈市: [114.879365, 30.447711],
        咸宁市: [114.328963, 29.832798],
        随州市: [113.37377, 31.717497],
        仙桃市: [113.453974, 30.364953],
        潜江市: [112.896866, 30.421215],
        天门市: [113.165862, 30.653061],
        恩施土家族苗族自治州: [109.4869983114],
        神农架林区: [110.671525, 31.74444],
        // 广东
        广东省:[113.23,23.16],
        广州市: [113.280637, 23.125178],
        韶关市: [113.591544, 24.801322],
        深圳市: [114.085947, 22.547],
        珠海市: [113.553986, 22.224979],
        汕头市: [116.708463, 23.37102],
        佛山市: [113.122717, 23.028762],
        江门市: [113.094942, 22.590431],
        湛江市: [110.364977, 21.274898],
        茂名市: [110.919229, 21.659751],
        肇庆市: [112.472529, 23.051546],
        惠州市: [114.412599, 23.079404],
        梅州市: [116.117582, 24.299112],
        汕尾市: [115.364238, 22.774485],
        河源市: [114.697802, 23.746266],
        阳江市: [111.975107, 21.859222],
        清远市: [113.051227, 23.685022],
        东莞市: [113.746262, 23.046237],
        中山市: [113.382391, 22.521113],
        潮州市: [116.632301, 23.661701],
        揭阳市: [116.355733, 23.543778],
        云浮市: [112.044439, 22.929801],
        // 贵州
        贵州省:[106.71,26.57],
        贵阳市: [106.713478, 26.578343],
        遵义市: [106.937265, 27.706626],
        六盘水市: [104.846743, 26.584643],
        安顺市: [105.932188, 26.245544],
        毕节市: [105.28501, 27.301693],
        铜仁市: [109.191555, 27.718346],
        黔西南布依族苗族自治州: [104.897971, 25.08812],
        黔东南苗族侗族自治州: [107.977488, 26.583352],
        黔南布依族苗族自治州: [107.517156, 26.258219],
        // 青海
        青海省:[101.74,36.56],
        西宁市: [101.778916, 36.623178],
        海东市: [102.10327, 36.502916],
        海北藏族自治州: [100.901059, 36.959435],
        黄南藏族自治州: [102.019988, 35.517744],
        海南藏族自治州: [100.619542, 36.280353],
        果洛藏族自治州: [100.242143, 34.4736],
        玉树藏族自治州: [97.008522, 33.004049],
        海西蒙古族藏族自治州: [97.370785, 37.374663],
        // 辽宁
        辽宁省:[123.38,41.8],
        沈阳市: [123.429096, 41.796767],
        大连市: [121.618622, 38.91459],
        鞍山市: [122.995632, 41.110626],
        抚顺市: [123.921109, 41.875956],
        本溪市: [123.770519, 41.297909],
        丹东市: [124.383044, 40.124296],
        锦州市: [121.135742, 41.119269],
        营口市: [122.235151, 40.667432],
        阜新市: [121.648962, 42.011796],
        辽阳市: [123.18152, 41.269402],
        盘锦市: [122.06957, 41.124484],
        铁岭市: [123.844279, 42.290585],
        朝阳市: [120.451176, 41.576758],
        葫芦岛市: [120.856394, 40.755572],
        // 江苏
        江苏省:[118.78,32.04],
        南京市: [118.767413, 32.041544],
        无锡市: [120.301663, 31.574729],
        徐州市: [117.184811, 34.261792],
        常州市: [119.946973, 31.772752],
        南通市: [120.864608, 32.016212],
        苏州市: [120.619585, 31.299379],
        连云港市: [119.178821, 34.600018],
        淮安市: [119.021265, 33.597506],
        盐城市: [120.139998, 33.377631],
        扬州市: [119.421003, 32.393159],
        镇江市: [119.452753, 32.204402],
        泰州市: [119.915176, 32.484882],
        宿迁市: [118.275162, 33.963008],
        // 安徽
        安徽省:[117.27,31.86],
        合肥市: [117.283042, 31.86119],
        芜湖市: [118.376451, 31.326319],
        蚌埠市: [117.363228, 32.939667],
        淮南市: [117.018329, 32.647574],
        马鞍山市: [118.507906, 31.689362],
        淮北市: [116.794664, 33.971707],
        铜陵市: [117.816576, 30.929935],
        安庆市: [117.043551, 30.50883],
        黄山市: [118.317325, 29.709239],
        滁州市: [118.316264, 32.303627],
        阜阳市: [115.819729, 32.896969],
        宿州市: [116.984084, 33.633891],
        六安市: [116.507676, 31.752889],
        亳州市: [115.782939, 33.869338],
        池州市: [117.489157, 30.656037],
        宣城市: [118.757995, 30.945667],
        // 河北省
        河北省:[114.48,38.03],
        石家庄市:[114.48,38.03],
        唐山市:[118.02,39.63],
        秦皇岛市:[119.57,39.95],
        邯郸市:[114.47,36.6],
        邢台市:[114.48,37.05],
        保定市:[115.48,38.85],
        张家口市:[114.87,40.82],
        承德市:[117.93,40.97],
        沧州市:[116.83,38.33],
        廊坊市:[116.7,39.53],
        衡水市:[115.72,37.72],
        // 山西省
        山西省:[112.53,37.87],
        太原市:[112.53,37.87],
        大同市:[113.3,40.12],
        阳泉市:[113.57,37.85],
        长治市:[113.08,36.18],
        晋城市:[112.83,35.52],
        朔州市:[112.43,39.33],
        晋中市:[112.74,37.7],
        运城市:[110.97,35.03],
        忻州市:[112.73,38.42],
        临汾市:[111.5,36.08],
        吕梁市:[111.13,37.52],
        // 黑龙江省
        黑龙江省:[126.63,45.75],
        哈尔滨市:[126.63,45.75],
        齐齐哈尔市:[123.97,47.33],
        鹤岗市:[130.3,47.33],
        鸡西市:[130.97,45.3],
        双鸭山市:[131.17,46.65],
        大庆市:[125.03, 46.58],
        伊春市:[128.92,47.73],
        佳木斯市:[130.35,46.83],
        七台河市:[130.83,45.82],
        牡丹江市:[129.58,44.6],
        黑河市:[127.53,50.22],
        绥化市:[127,46.63],
        大兴安岭地区:[124.71,52.34],
        // 福建省
        福建省:[119.3,26.08],
        福州市:[119.3,26.08],
        厦门市:[118.1,24.46],
        莆田市:[119,25.44],
        三明市:[117.61,26.23],
        泉州市:[118.58,24.93],
        漳州市:[117.35,24.52],
        南平市:[118.16,26.65],
        龙岩市:[117.01,25.12],
        宁德市:[119.52,26.65],
        // 山东省
        山东省:[117,36.65],
        济南市:[117,36.65],
        青岛市:[120.33,36.07],
        淄博市:[118.05,36.78],
        枣庄市:[117.57,34.86],
        东营市:[118.49,37.46],
        烟台市:[121.39,37.52],
        潍坊市:[119.1,36.62],
        济宁市:[116.59,35.38],
        泰安市:[117.13,36.18],
        威海市:[122.1,37.5],
        日照市:[119.46,35.42],
        临沂市:[118.35,35.05],
        德州市:[116.29,37.45],
        聊城市:[115.97,36.45],
        滨州市:[118.03,37.36],
        菏泽市:[115.47,35.25],
        // 湖南省
        湖南省:[113,28.21],
        长沙市:[113,28.21],
        株洲市:[113.16,27.83],
        湘潭市:[112.91,27.87],
        衡阳市:[112.61,26.89],
        邵阳市:[111.5,27.22],
        岳阳市:[113.09,29.37],
        常德市:[111.69,29.05],
        张家界市:[110.48,29.13],
        益阳市:[112.33,28.6],
        郴州市:[113,25.79],
        永州市:[111.63,26.22],
        怀化市:[109.95,27.52],
        娄底市:[111.96,27.71],
        湘西土家族苗族自治州:[109.74,28.31],
        // 海南省
        海南省:[110.35,20.02],
        海口市:[110.35,20.02],
        三亚市:[109.51,18.25],
        三沙市:[112.35,16.83],
        儋州市:[109.58,19.52],
        五指山市:[109.52,18.78],
        琼海市:[110.46,19.25],
        文昌市:[110.72,19.61],
        万宁市:[110.39,18.8],
        东方市:[108.64,19.09],
        定安县:[110.31,19.68],
        屯昌县:[110.1,19.36],
        澄迈县:[110,19.75],
        临高县:[109.69,19.91],
        白沙黎族自治县:[109.44,19.23],
        昌江黎族自治县:[109.03,19.25],
        乐东黎族自治县:[109.17,18.73],
        陵水黎族自治县:[110.02,18.48],
        保亭黎族苗族自治县:[109.7,18.64],
        琼中黎族苗族自治县:[109.83,19.05],
        // 云南省
        云南省:[102.73,25.04],
        昆明市:[102.73,25.04],
        曲靖市:[103.79,25.51],
        玉溪市:[102.52,24.35],
        保山市:[99.17,25.11],
        昭通市:[103.7,29.32],
        丽江市:[100.25,26.86],
        普洱市:[101.03,23.07],
        临沧市:[100.09,23.89],
        楚雄彝族自治州:[101.54,25.01],
        红河哈尼族彝族自治州:[102.42,23.35],
        文山壮族苗族自治州:[104.24,23.37],
        西双版纳傣族自治州:[100.80,22.00],
        大理白族自治州:[100.19,25.69],
        德宏傣族景颇族自治州:[98.58,24.44],
        怒江傈僳族自治州:[98.85,25.85],
        迪庆藏族自治州:[99.71,27.83],
        // 宁夏
        宁夏回族自治区:[106.27,38.47],
        银川市:[106.27,38.47],
        石嘴山市:[106.39,39.04],
        吴忠市:[106.21,37.99],
        固原市:[106.28,36.01],
        中卫市:[105.18,37.51],
      };
      var res = [];
      if(that.cityOption) {
        that.cityOption.name[0].forEach((item,index)=>{
        var geoCoord = geoCoordMap[item];
          if (geoCoord) {
            res.push({
              name: item,
              value: geoCoord.concat(that.cityOption.num[0][index]),
            });
          }
      })
      }
      this.dataRes = res
    },
    // 首页地图
    getMapChart(name = null,dataParams) {
      // 维度
      var showConfStr = this.pageContent.showConf;
      this.dimensionValue = showConfStr;
      //具体维度下显示内容
      var metaData = this.pageContent[showConfStr];
      this.statisticalValue1 = metaData;
      if(!this.flagCity){
        
      }
      if (showConfStr === "appInfo"&&this.flagCity) {
        this.cityTitle = "应用威胁次数";
        this.statistical = this.appInfo_statistical;
        this.chartsTu = this.appChart;
        this.chartsValue = "map";
        this.metaMapData = this.mapData[showConfStr].alarmInfo;
      }
      if (showConfStr === "deviceInfo") {
        this.cityTitle = "设备违规次数";
        this.statistical = this.deviceInfo_statistical;
        this.chartsTu = this.appChart;
        this.chartsValue = "map";
        this.metaMapData = this.mapData[showConfStr].violaInfo;
      }
      if (showConfStr === "userInfo") {
        this.statistical = this.userInfo_statistical;
        this.chartsTu = this.userChart;
        this.chartsValue = "";
        this.metaMapData = this.mapData[showConfStr].complianceStatus;
      }
      
      this.flagCity = true
      
        this.newArr = []
        this.newArr2 = []
        var arr = this.metaMapData      
      this.province_city = new Map()
      for (var k in arr) {
        this.newArr.push(
          arr[k].province,
        )
        this.newArr2.push(
          arr[k].count,
        )

        // if ("北京" === arr[k].province) {
        //   arr[k].province = "北京市";
        // }
        // } else if ("天津市" === arr[k].province) {
        //   arr[k].province = "天津";
        // } else if ("上海市" === arr[k].province) {
        //   arr[k].province = "上海";
        // } else if ("重庆市" === arr[k].province) {
        //   arr[k].province = "重庆";
        // } else if ("河北省" === arr[k].province) {
        //   arr[k].province = "河北";
        // } else if ("山西省" === arr[k].province) {
        //   arr[k].province = "山西";
        // } else if ("辽宁省" === arr[k].province) {
        //   arr[k].province = "辽宁";
        // } else if ("江苏省" === arr[k].province) {
        //   arr[k].province = "江苏";
        // } else if ("浙江省" === arr[k].province) {
        //   arr[k].province = "浙江";
        // } else if ("安徽省" === arr[k].province) {
        //   arr[k].province = "安徽";
        // } else if ("福建省" === arr[k].province) {
        //   arr[k].province = "福建";
        // } else if ("江西省" === arr[k].province) {
        //   arr[k].province = "江西";
        // } else if ("山东省" === arr[k].province) {
        //   arr[k].province = "山东";
        // } else if ("河南省" === arr[k].province) {
        //   arr[k].province = "河南";
        // } else if ("湖北省" === arr[k].province) {
        //   arr[k].province = "湖北";
        // } else if ("湖南省" === arr[k].province) {
        //   arr[k].province = "湖南";
        // } else if ("海南省" === arr[k].province) {
        //   arr[k].province = "海南";
        // } else if ("四川省" === arr[k].province) {
        //   arr[k].province = "四川";
        // } else if ("贵州省" === arr[k].province) {
        //   arr[k].province = "贵州";
        // } else if ("云南省" === arr[k].province) {
        //   arr[k].province = "云南";
        // }
        // else if ('陕西省' === arr[k].province) {
        //   arr[k].province = '陕西省'
        // } else if ('黑龙江省' === arr[k].province) {
        //   arr[k].province = '黑龙江'
        // } else if ("澳门特别行政区" === arr[k].province) {
        //   arr[k].province = '澳门'
        // }
        // else if ("香港特别行政区，香港特别行政区，中西区" === arr[k].province) {
        //   arr[k].province = '香港'
        // }
        // else if ("香港特别行政区" === arr[k].province) {
        //   arr[k].province = '香港'
        // }
        // else if ("新疆维吾尔自治区" === arr[k].province) {
        //   arr[k].province = "新疆";
        // } else if ("西藏自治区" === arr[k].province) {
        //   arr[k].province = "西藏";
        // } else if ("广西壮族自治区" === arr[k].province) {
        //   arr[k].province = "广西";
        // } else if ("境外" === arr[k].province) {
        //   arr[k].province = "境外";
        // } else if ("内蒙古自治区" === arr[k].province) {
        //   arr[k].province = "内蒙古";
        // } else if ("宁夏回族自治区" === arr[k].province) {
        //   arr[k].province = "宁夏";
        // } else if ("吉林省" === arr[k].province) {
        //   arr[k].province = "吉林";
        // } else if ("广东省" === arr[k].province) {
        //   arr[k].province = "广东";
        // }

        // else if ("甘肃省" === arr[k].province) {
        //   arr[k].province = "甘肃";
        // } else if ("湖南省" === arr[k].province) {
        //   arr[k].province = "湖南";
        // } else if ("青海省" === arr[k].province) {
        //   arr[k].province = "青海";
        // } else if ("台湾省" === arr[k].province) {
        //   arr[k].province = "台湾";
        // }
        let array = { name :[],
                     num:[]}; 

        array.name.push (arr[k].name) ; 
        array.num.push (arr[k].num)

        this.province_city.set(arr[k].province,array);
       
      }
      
      
    

      var showData = [];

      var columnarX = this.newArr;
      var columnarY = this.newArr2;
      for (var i = 0; i < this.metaMapData.length; i++) {
        var columnar = {
          value: "",
          name: "",
        };
        //需要映射显示中文描述

        if ("北京市" === columnarX[i]) {
          columnar.name = "北京";
        } else if ("天津市" === columnarX[i]) {
          columnar.name = "天津";
        } else if ("上海市" === columnarX[i]) {
          columnar.name = "上海";
        } else if ("重庆市" === columnarX[i]) {
          columnar.name = "重庆";
        } else if ("河北省" === columnarX[i]) {
          columnar.name = "河北";
        } else if ("山西省" === columnarX[i]) {
          columnar.name = "山西";
        } else if ("辽宁省" === columnarX[i]) {
          columnar.name = "辽宁";
        } else if ("江苏省" === columnarX[i]) {
          columnar.name = "江苏";
        } else if ("浙江省" === columnarX[i]) {
          columnar.name = "浙江";
        } else if ("安徽省" === columnarX[i]) {
          columnar.name = "安徽";
        } else if ("福建省" === columnarX[i]) {
          columnar.name = "福建";
        } else if ("江西省" === columnarX[i]) {
          columnar.name = "江西";
        } else if ("山东省" === columnarX[i]) {
          columnar.name = "山东";
        } else if ("河南省" === columnarX[i]) {
          columnar.name = "河南";
        } else if ("湖北省" === columnarX[i]) {
          columnar.name = "湖北";
        } else if ("湖南省" === columnarX[i]) {
          columnar.name = "湖南";
        } else if ("海南省" === columnarX[i]) {
          columnar.name = "海南";
        } else if ("四川省" === columnarX[i]) {
          columnar.name = "四川";
        } else if ("贵州省" === columnarX[i]) {
          columnar.name = "贵州";
        } else if ("云南省" === columnarX[i]) {
          columnar.name = "云南";
        }
        else if ('陕西省' === columnarX[i]) {
          columnar.name = '陕西省'
        } else if ('黑龙江省' === columnarX[i]) {
          columnar.name = '黑龙江'
        } else if ("澳门特别行政区" === columnarX[i]) {
          columnar.name = '澳门'
        }
        else if ("香港特别行政区，香港特别行政区，中西区" === columnarX[i]) {
          columnar.name = '香港'
        }
        else if ("香港特别行政区" === columnarX[i]) {
          columnar.name = '香港'
        }
        else if ("新疆维吾尔自治区" === columnarX[i]) {
          columnar.name = "新疆";
        } else if ("西藏自治区" === columnarX[i]) {
          columnar.name = "西藏";
        } else if ("广西壮族自治区" === columnarX[i]) {
          columnar.name = "广西";
        } else if ("境外" === columnarX[i]) {
          columnar.name = "境外";
        } else if ("内蒙古自治区" === columnarX[i]) {
          columnar.name = "内蒙古";
        } else if ("宁夏回族自治区" === columnarX[i]) {
          columnar.name = "宁夏";
        } else if ("吉林省" === columnarX[i]) {
          columnar.name = "吉林";
        } else if ("广东省" === columnarX[i]) {
          columnar.name = "广东";
        }
        //  else if ("N/A" === columnarX[i]) {
        //   columnar.name = "";
        // } 

        else if ("甘肃省" === columnarX[i]) {
          columnar.name = "甘肃";
        } else if ("湖南省" === columnarX[i]) {
          columnar.name = "湖南";
        } else if ("青海省" === columnarX[i]) {
          columnar.name = "青海";
        } else if ("台湾省" === columnarX[i]) {
          columnar.name = "台湾";
        }
        // else{
        //    columnar.name =  columnar.name
        // }
        columnar.value = columnarY[i];
        showData.push(columnar);

      }
      showData.forEach((item, index) => {
        if (item.name == '') {
          showData.splice(index, 1)
        }
      })
      name == 'china' ? this.isReturnChina = false : this.isReturnChina = true;
      let _this = this;
      var myChart = echarts.init(this.$refs.map);
      var option;
      const data = showData ? showData.splice(0, 10) : [];
      var geoCoordMap = {
        黑龙江: [126.63, 45.75],
        陕西省: [108.95, 34.27],
        山西省: [100.548879, 37.870590],
        上海: [121.4648, 31.2891],
        新疆: [87.9236, 43.5883],
        甘肃: [103.5901, 36.3043],
        北京: [116.4551, 40.2539],
        江苏: [118.8062, 31.9208],
        广西: [108.479, 23.1152],
        江西: [116.0046, 28.6633],
        安徽: [117.29, 32.0581],
        内蒙古: [111.4124, 40.4901],
        天津: [117.4219, 39.4189],
        山西: [112.3352, 37.9413],
        广东: [113.5107, 23.2196],
        四川: [103.9526, 30.7617],
        西藏: [91.1865, 30.1465],
        云南: [102.9199, 25.4663],
        浙江: [119.5313, 29.8773],
        湖北: [114.3896, 30.6628],
        辽宁: [123.1238, 42.1216],
        山东: [117.1582, 36.8701],
        河北: [114.4995, 38.1006],
        福建: [119.4543, 25.9222],
        陕西: [109.1162, 34.2004],
        贵州: [106.6992, 26.7682],
        河南: [113.4668, 34.6234],
        重庆: [107.7539, 30.1904],
        湖南: [113.0823, 28.2568],
        宁夏: [106.3586, 38.1775],
        海南: [110.3893, 19.8516],
        香港: [114.2049, 22.2459],
        澳门: [113.5764, 22.1578],
        台湾: [121.5637, 25.0374],
        境外: [132.651, 35.8128],
        青海: [101.7798, 36.6296],
        海门: [121.15, 31.89],
        鄂尔多斯: [109.781327, 39.608266],
        招远: [120.38, 37.35],
        舟山: [122.207216, 29.985295],
        齐齐哈尔: [123.97, 47.33],
        盐城: [120.13, 33.38],
        赤峰: [118.87, 42.28],
        青岛: [120.33, 36.07],
        乳山: [121.52, 36.89],
        金昌: [102.188043, 38.520089],
        泉州: [118.58, 24.93],
        莱西: [120.53, 36.86],
        日照: [119.46, 35.42],
        胶南: [119.97, 35.88],
        南通: [121.05, 32.08],
        拉萨: [91.11, 29.97],
        云浮: [112.02, 22.93],
        梅州: [116.1, 24.55],
        文登: [122.05, 37.2],
        上海: [121.48, 31.22],
        攀枝花: [101.718637, 26.582347],
        威海: [122.1, 37.5],
        承德: [117.93, 40.97],
        厦门: [118.1, 24.46],
        汕尾: [115.375279, 22.786211],
        潮州: [116.63, 23.68],
        丹东: [124.37, 40.13],
        太仓: [121.1, 31.45],
        曲靖: [103.79, 25.51],
        烟台: [121.39, 37.52],
        福州: [119.3, 26.08],
        瓦房店: [121.979603, 39.627114],
        即墨: [120.45, 36.38],
        抚顺: [123.97, 41.97],
        玉溪: [102.52, 24.35],
        张家口: [114.87, 40.82],
        阳泉: [113.57, 37.85],
        莱州: [119.942327, 37.177017],
        湖州: [120.1, 30.86],
        汕头: [116.69, 23.39],
        昆山: [120.95, 31.39],
        宁波: [121.56, 29.86],
        湛江: [110.359377, 21.270708],
        揭阳: [116.35, 23.55],
        荣成: [122.41, 37.16],
        连云港: [119.16, 34.59],
        葫芦岛: [120.836932, 40.711052],
        常熟: [120.74, 31.64],
        东莞: [113.75, 23.04],
        河源: [114.68, 23.73],
        淮安: [119.15, 33.5],
        泰州: [119.9, 32.49],
        南宁: [108.33, 22.84],
        营口: [122.18, 40.65],
        惠州: [114.4, 23.09],
        江阴: [120.26, 31.91],
        蓬莱: [120.75, 37.8],
        韶关: [113.62, 24.84],
        嘉峪关: [98.289152, 39.77313],
        广州: [113.23, 23.16],
        延安: [109.47, 36.6],
        太原: [112.53, 37.87],
        清远: [113.01, 23.7],
        中山: [113.38, 22.52],
        昆明: [102.73, 25.04],
        寿光: [118.73, 36.86],
        盘锦: [122.070714, 41.119997],
        长治: [113.08, 36.18],
        深圳: [114.07, 22.62],
        珠海: [113.52, 22.3],
        宿迁: [118.3, 33.96],
        咸阳: [108.72, 34.36],
        铜川: [109.11, 35.09],
        平度: [119.97, 36.77],
        佛山: [113.11, 23.05],
        海口: [110.35, 20.02],
        江门: [113.06, 22.61],
        章丘: [117.53, 36.72],
        肇庆: [112.44, 23.05],
        大连: [121.62, 38.92],
        临汾: [111.5, 36.08],
        吴江: [120.63, 31.16],
        石嘴山: [106.39, 39.04],
        沈阳: [123.38, 41.8],
        苏州: [120.62, 31.32],
        茂名: [110.88, 21.68],
        嘉兴: [120.76, 30.77],
        长春: [125.35, 43.88],
        胶州: [120.03336, 36.264622],
        银川: [106.27, 38.47],
        张家港: [120.555821, 31.875428],
        三门峡: [111.19, 34.76],
        锦州: [121.15, 41.13],
        南昌: [115.89, 28.68],
        柳州: [109.4, 24.33],
        三亚: [109.511909, 18.252847],
        自贡: [104.778442, 29.33903],
        吉林: [126.57, 43.87],
        阳江: [111.95, 21.85],
        泸州: [105.39, 28.91],
        西宁: [101.74, 36.56],
        宜宾: [104.56, 29.77],
        呼和浩特: [111.65, 40.82],
        成都: [104.06, 30.67],
        大同: [113.3, 40.12],
        镇江: [119.44, 32.2],
        桂林: [110.28, 25.29],
        张家界: [110.479191, 29.117096],
        宜兴: [119.82, 31.36],
        北海: [109.12, 21.49],
        西安: [108.95, 34.27],
        金坛: [119.56, 31.74],
        东营: [118.49, 37.46],
        牡丹江: [129.58, 44.6],
        遵义: [106.9, 27.7],
        绍兴: [120.58, 30.01],
        扬州: [119.42, 32.39],
        常州: [119.95, 31.79],
        潍坊: [119.1, 36.62],
        重庆: [106.54, 29.59],
        台州: [121.420757, 28.656386],
        南京: [118.78, 32.04],
        滨州: [118.03, 37.36],
        贵阳: [106.71, 26.57],
        无锡: [120.29, 31.59],
        本溪: [123.73, 41.3],
        克拉玛依: [84.77, 45.59],
        渭南: [109.5, 34.52],
        马鞍山: [118.48, 31.56],
        宝鸡: [107.15, 34.38],
        焦作: [113.21, 35.24],
        句容: [119.16, 31.95],
        北京: [116.46, 39.92],
        徐州: [117.2, 34.26],
        衡水: [115.72, 37.72],
        包头: [110, 40.58],
        绵阳: [104.73, 31.48],
        乌鲁木齐: [87.68, 43.77],
        枣庄: [117.57, 34.86],
        杭州: [120.19, 30.26],
        淄博: [118.05, 36.78],
        鞍山: [122.85, 41.12],
        溧阳: [119.48, 31.43],
        库尔勒: [86.06, 41.68],
        安阳: [114.35, 36.1],
        开封: [114.35, 34.79],
        济南: [117, 36.65],
        德阳: [104.37, 31.13],
        温州: [120.65, 28.01],
        九江: [115.97, 29.71],
        邯郸: [114.47, 36.6],
        临安: [119.72, 30.23],
        兰州: [103.73, 36.03],
        沧州: [116.83, 38.33],
        临沂: [118.35, 35.05],
        南充: [106.110698, 30.837793],
        天津: [117.2, 39.13],
        富阳: [119.95, 30.07],
        泰安: [117.13, 36.18],
        诸暨: [120.23, 29.71],
        郑州: [113.65, 34.76],
        哈尔滨: [126.63, 45.75],
        聊城: [115.97, 36.45],
        芜湖: [118.38, 31.33],
        唐山: [118.02, 39.63],
        平顶山: [113.29, 33.75],
        邢台: [114.48, 37.05],
        德州: [116.29, 37.45],
        济宁: [116.59, 35.38],
        荆州: [112.239741, 30.335165],
        宜昌: [111.3, 30.7],
        义乌: [120.06, 29.32],
        丽水: [119.92, 28.45],
        洛阳: [112.44, 34.7],
        秦皇岛: [119.57, 39.95],
        株洲: [113.16, 27.83],
        石家庄: [114.48, 38.03],
        莱芜: [117.67, 36.19],
        常德: [111.69, 29.05],
        保定: [115.48, 38.85],
        湘潭: [112.91, 27.87],
        金华: [119.64, 29.12],
        岳阳: [113.09, 29.37],
        长沙: [113, 28.21],
        衢州: [118.88, 28.97],
        廊坊: [116.7, 39.53],
        菏泽: [115.480656, 35.23375],
        合肥: [117.27, 31.86],
        武汉: [114.31, 30.52],
        大庆: [125.03, 46.58],
      };
      // 不是全国地图的时候  让市级经纬度坐标为空
      if(name!="china"){
        geoCoordMap={}
      } 

      var convertData1 = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
            });
          }
        }
        return res;
      };

      //设置一些可选的参数
      option = {
        //设置背景颜色
        backgroundColor: "rgba(0, 0, 0, 0)", //rgba设置透明度0.1
        //设置图片标题、子标题、文本颜色等等
        title: {
          // text: "echarts使用1",
          // subtext: "移动数据监测态势",
          left: "center",
          textStyle: {
            color: "#000",
          },
        },
        tooltip: {
          trigger: "item",
          // triggerOn: 'click', //这里根据业务需要给点击事件做跳转,所以不能做悬浮了,如果做悬浮 点击不了文字跳转
          formatter: function (params) { 
            
           // 判断当前 是小公鸡还是  地区
             return `${params.seriesName}</br> ${params.data.name}:${params.data.value[2]}`; 
          },
          // formatter: function (params) {
          //   var name = params.name
          //   var toolipData = []
          //   data.forEach(item => {
          //     console.log(item, '---当天数据')
          //     if (name == item.name) {
          //       toolipData = item.children
          //     }
          //   })
          //   var htmlStr = ''
          //   toolipData.forEach(item => {
          //     htmlStr += `<li class="list-li" name="${item}"><div οnclick="clickRoute()" >${item}</div></li>`
          //   })
          //   return `<div class="list-wrap"><div class="list-title">${name}</div><ul class="list-ul">${htmlStr}</ul></div>`
          // },
        },
        geo: {
          map: name ? name : "china", // 核心
          roam: false,
          regions: [
            {

              name: "南海诸岛",
              itemStyle: {
                // 隐藏地图
                normal: {
                  opacity: 1, // 为 0 时不绘制该图形
                },
              },
              label: {
                show: false, // 隐藏文字
              },
            },
          ],
          // map: "china",
          aspectScale: 0.8, //echarts地图的长宽比（就是胖瘦）
          // center: [104.795619, 35.224029], //设置可见中心坐标，以此坐标来放大和缩小
          zoom: 1.2, //放大级别

          label: {// 页面初始化加载的文字
            // 地图显示文字
            // normal: {
            //                 show: true,
            //                 textStyle: {
            //                     color: "#ccc", // 页面初始化的地图文字颜色
            //                     fontSize:10// // 页面初始化的地图文字大小
            //                 },
            //             },
            emphasis: {
              show: true,
            },
          },

          itemStyle: {
            normal: {
              borderWidth: 1, //boreder的粗细
              areaColor: "#034689", //整个地图每一块地图的色
              // areaColor: {
              //                   x: 0,
              //                   y: 0,
              //                   x2: 0,
              //                   y2: 1,
              //                   colorStops: [{
              //                       offset: 0,
              //                       color: '#073684' // 0% 处的颜色
              //                   }, {
              //                       offset: 1,
              //                       color: '#061E3D' // 100% 处的颜色
              //                   }],
              //               },
              borderColor: "#19BCFF", //整个地图的border颜色
              shadowColor: "rgba(1, 39, 44, 1)", //rgba(1, 39, 44, 1)设置阴影
              shadowOffsetX: 0,
              shadowOffsetY: 12,
            },
            emphasis: {
              show: true,
              textStyle: {
                color: '#000',
                fontSize: "12px"
              },
              borderWidth: 1, //滑过的border线 粗细
              borderColor: "#E6E3E0", //滑过的background 粗细
              areaColor: "#0466fb", //鼠标滑过 地图的颜色 
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          // roam: false,
        },

        //series就是要绘制的地图的主体。是一个数组，也就是说可以有多个数据进行绘制。这里有两个，一个是两个城市的连线，一个是对两个城市进行高亮显示。其中的type是很重要的参数，主要有饼图、条形图、线、地图等等。具体的可以去参考官网上的配置手册。
        series: [
          {
            name: "rode",
            type: "lines", //lines  这个属性是城市  + 39.56
            // mapType: 'china',
            geoIndex: 0,
            coordinateSystem: "geo",
            //data: showData,
            effect: {
              show: true,
              period: 6,
              trailLength: 0,
            },
            itemStyle: {
              //每一项数据颜色
              normal: {
                color: "#6CFBFF",
              },
              label: {
                show: true,//是否显示标签
                textStyle: {
                  color: "black"
                }
              },
              zoom: 1.5,  //地图缩放比例,默认为1
              emphasis: {//是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
                label: { show: true }
              }
            },
            lineStyle: {
              normal: {
                color: "#389BB7",
                width: 1,
                opacity: 0.4,
                curveness: 0.2,
              },
            },
            // 202212-06 10.07
            rippleEffect: {
              brushType: 'fill',
              scale: 0
            },
            showEffectOn: 'render',
            label: {
              lineHeight: 30,
              normal: {
                show: true,
                color: '#081727',
                position: 'inside',
                padding: [5, 0, 0, 0],
                verticalAlign: 'middle',
                formatter: function (para) {
                  return '{cnNum|' + para.data.value[2] + '}'
                  // return para.data.value[2]
                },
                rich: {
                  cnNum: {
                    fontSize: 16,
                    color: '#081727',
                    lineHeight: 28,
                  }
                }
              },
              emphasis: {
                show: true,
                textStyle: {
                }
              }
            },
            symbol: 'roundRect',
            // symbolRotate: 20,
            symbolSize: [40, 28],
            zlevel: 1,
          },
          {
            name: this.cityTitle,
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              brushType: "stroke",
            },
            label: {
              normal: {
                show: true,
                position: "right",
                formatter: "{b}",
              },
            },
            symbolSize: 12, //涟漪大小设置
            itemStyle: {
              shadowBlur: 10,
              //每一项数据颜色
              normal: {
                color: ["#6CFBFF"],
              },
            },
            data: dataParams.length>0?dataParams:convertData1(data),
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            rippleEffect: {
              brushType: 'fill',
              scale: 0
            },
            showEffectOn: 'render',
            label: {
              lineHeight: 30,
              normal: {
                show: true,
                color: '#081727',
                position: 'inside',
                padding: [5, 0, 0, 0],
                verticalAlign: 'middle',
                formatter: function (para) {
                  return '{cnNum|' + para.data.value[2] + '}'
                  // return para.data.value[2]
                },
                rich: {
                  cnNum: {
                    fontSize: 16,
                    color: '#081727',
                    lineHeight: 28,
                  }
                }
              },
            },
            symbol: 'roundRect',
            // symbolRotate: 20,
            symbolSize: [40, 28],
            data: [],
            zlevel: 1,
          }
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      this.chinaMapHidden(myChart)

      let erd = elementResizeDetectorMaker();
      erd.listenTo(this.$refs.map, () => {
        _this.$nextTick(() => {
          myChart.resize();
        });
      });
      myChart.on("click", (param) => {
        let ObjectArr = {
          name:param.data?param.data.name:"",
          dataDay: this.taDay,
          starttime: this.startDay,
        }
        this.$emit('nameCity',ObjectArr)
        //触发回调传给父组件 父组件在给table组件
      }
      )
    },
    // 点击跳转页面
    clickRoute() {
      alert("点击了,做其他操作!")
      // this.$router.push('/')
    },
  },
};
</script>

<style lang="scss" scoped>
.echartsGroup {
  width: 500px;
  height: 500px;
  position: relative;

}

.echartsGroup .button {
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 30px;
  z-index: 99;
  // background-color:#034689;
  color: white;
}

.list-ul {
  list-style: none;
}

.list-li {
  pointer-events: all;
  cursor: pointer;
}

v-deep .popoverBackB {
  /* #303133是el-tooltip的背景色 */
  background: #303133 !important;
}

::v-deep .popoverBackB .popper__arrow::after {
  /* 注意：placement位置不同，下面的属性不同 */
  border-bottom-color: #303133 !important;
}

// 齿轮设置
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.headerTu {
  background: url("../../../assets/heard.png"),
    //背景图
    rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>