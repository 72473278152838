<template>
  <div class="taishi">
    <el-row :gutter="20" style="height: 100%">
      <el-col :span="24" style="height: 100%">
        <div style="width: 100%; height: 100%">
          <div
            class="header"
            style="
              width: 100%;
              height: 10%;
              display: flex;
              position: relative;
              justify-content: space-around;
            "
          >
            <img
              src="../assets/top.png"
              alt=""
              srcset=""
              style="width: 100%; height: 100%; position: absolute;top:0.left:0; position: relative;"
            />
            <div
              style="
                width: 100%;
                color: white;
                height: 100%;
                display: flex;
                justify-content: space-around;
                position: absolute;
                top: 20%;
                left: 0;
              "
            >
              <div class="zuo">
                <!-- <span style="">{{ this.wholeTime }}</span> -->
                <span style="font-size: 20px"
                  >{{ this.nowDate }}&nbsp;&nbsp; {{ this.nowTime }}&nbsp;&nbsp;
                  {{ this.nowWeek }}</span
                >
              </div>
              <div class="zhong">
                &nbsp;&nbsp;
                <span class="fontFamily">{{ pageContent.titleInfo.titleName }}</span>
              </div>
              <div class="right">
                <div style="display: flex; justify-content: space-around">
                  <ul
                    v-for="item in threatSwitch"
                    :key="item"
                    @click="changeData2(item)"
                    class="aside-name-active"
                    :class="{ active: active == item }"
                    style="
                      width: 100px;
                      display: flex;
                      height: 30px;
                      cursor: pointer;
                      font-size: 20px;
                    "
                  >
                    <li style="display: inline; width: 100px">
                      {{ item }}
                    </li>
                  </ul>
                  <!-- <div @click="zeroQuery" style="cursor: pointer">当天&nbsp;&nbsp;</div>
                  <div @click="oneQuery" style="cursor: pointer">近7天&nbsp;&nbsp;</div>
                  <div @click="twoQuery" style="cursor: pointer">近30天&nbsp;&nbsp;</div>
                  <div @click="threeQuery" style="cursor: pointer">近90天&nbsp;&nbsp;</div> -->
                  <div>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="设置"
                      placement="bottom-start"
                    >
                      <span
                        @click="goBack"
                        style="z-index: 2; cursor: pointer; width: 60px"
                        class="icon iconfont icon-shezhi"
                      ></span>
                    </el-tooltip>

                    &nbsp;&nbsp;
                  </div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="退出"
                      placement="bottom-start"
                    >
                      <span @click="goOut">
                        <svg
                        class="icon"
                        aria-hidden="true"
                        style="z-index: 2; cursor: pointer; margin-left: 30px"
                      >
                        <use xlink:href="#icon-tuichuquanping"></use>
                      </svg>
                      </span>
                      
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sevurbox">
            <!-- 左 1、2、3 -->
            <div class="left1">
              <div style="width: 100%; height: 100%">
                <dv-border-box-12
                  class="leftTop1"
                  style="width: 100%; height: 20%; margin-top: 2%"
                >
                  <div style="width: 100%; height: 100%; display: flex">
                    <div style="width: 50%; height: 100%">
                      <cartLeft
                        :queryCount="queryCount"
                        v-if="cardDataSum"
                        :cardDataSum="cardDataSum"
                        ref="cardLeft1"
                        :pageContent="pageContent.cardInfo.cardInfoLeft_1"
                      />
                    </div>
                    <div style="width: 50%; height: 100%">
                      <!-- " -->
                      <cartLeft2
                        :queryCount="queryCount"
                        v-if="cardDataSum"
                        :cardDataSum="cardDataSum"
                        ref="cardLeft2"
                        :pageContent="pageContent.cardInfo.cardInfoLeft_2"
                      />
                    </div>
                  </div>
                </dv-border-box-12>
                <dv-border-box-11
                  :color="['#7CE7FD', '#2E6099']"
                  :title="pageContent.chartInfo.chartInfoLeft_1.titleName"
                  class="leftTop2"
                  style="width: 100%; height: 35%; margin-top: 2%"
                >
                  <div style="width: 100%; height: 100%">
                    <activeTrend
                      :queryCount="queryCount"
                      v-if="echartData"
                      :echartData="echartData"
                      :appInstalls="appInstalls"
                      ref="chartsLeft1"
                      :pageContent="pageContent.chartInfo.chartInfoLeft_1"
                    />
                  </div>
                </dv-border-box-11>
                <dv-border-box-11
                  :color="['#7CE7FD', '#2E6099']"
                  :title="pageContent.chartInfo.chartInfoLeft_2.titleName"
                  class="leftTop3"
                  style="width: 100%; height: 35%; margin-top: 2%"
                >
                  <div style="width: 100%; height: 100%">
                    <activeTop5
                      :queryCount="queryCount"
                      v-if="echartData"
                      :echartData="echartData"
                      :appInstalls="appInstalls"
                      ref="chartsLeft2"
                      :pageContent="pageContent.chartInfo.chartInfoLeft_2"
                    />
                  </div>
                </dv-border-box-11>
              </div>
            </div>
            <!-- 中 1、2-->
            <div class="center1">
              <div class="leftTop1" style="width: 100%; height: 53%; margin-top: 2%">
                <div class="beijing" style="width: 100%; height: 100%">
                  <china
                    :queryCount="queryCount"
                    v-if="mapData"
                    @nameCity="nameCity"
                    :mapData="mapData"
                    :pageContent="pageContent.mapInfo"
                  />
                </div>
              </div>
              <div class="leftTop2" style="width: 100%; height: 30%; margin-top: 2%">
                <div style="width: 100%; height: 100%">
                  <tables
                    :queryCount="queryCount"
                    v-if="nameCityFlag"
                    :nameCityText="nameCityText"
                    ref="tablesRef"
                    :pageContent="pageContent.tableInfo"
                  />
                </div>
              </div>
            </div>
            <!-- 右 1、2、3-->
            <div class="right1">
              <dv-border-box-12
                class="leftTop1"
                style="width: 100%; height: 20%; margin-top: 2%"
              >
                <div style="width: 100%; height: 100%; display: flex">
                  <div style="width: 50%; height: 100%">
                    <!--  -->
                    <cartRight
                      :queryCount="queryCount"
                      v-if="cardDataSum"
                      :cardDataSum="cardDataSum"
                      ref="cardRight1"
                      :pageContent="pageContent.cardInfo.cardInfoRight_1"
                    />
                  </div>
                  <div style="width: 50%; height: 100%">
                    <!--  -->
                    <cartRight2
                      :queryCount="queryCount"
                      v-if="cardDataSum"
                      :cardDataSum="cardDataSum"
                      ref="cardRight2"
                      :pageContent="pageContent.cardInfo.cardInfoRight_2"
                    />
                  </div>
                </div>
              </dv-border-box-12>
              <dv-border-box-11
                :color="['#7CE7FD', '#2E6099']"
                :title="pageContent.chartInfo.chartInfoRight_1.titleName"
                class="leftTop2"
                style="width: 100%; height: 35%; margin-top: 2%"
              >
                <div style="width: 100%; height: 100%">
                  <threatTrend
                    :queryCount="queryCount"
                    v-if="echartData"
                    :echartData="echartData"
                    :appInstalls="appInstalls"
                    ref="chartsRight1"
                    :pageContent="pageContent.chartInfo.chartInfoRight_1"
                  />
                </div>
              </dv-border-box-11>
              <dv-border-box-11
                :color="['#7CE7FD', '#2E6099']"
                :title="pageContent.chartInfo.chartInfoRight_2.titleName"
                class="leftTop3"
                style="width: 100%; height: 35%; margin-top: 2%"
              >
                <div style="width: 100%; height: 100%">
                  <threatTop5
                    :queryCount="queryCount"
                    v-if="echartData"
                    :echartData="echartData"
                    :appInstalls="appInstalls"
                    ref="chartsRight2"
                    :pageContent="pageContent.chartInfo.chartInfoRight_2"
                  />
                </div>
              </dv-border-box-11>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Eldialog from "@/components/elDialog"; //详情弹框
import "../assets/iconfont/iconfont.js"; //小齿轮设置的icon
import cartLeft2 from "./previewPage/compontes/cartLeft2.vue"; //卡片2左边
import cartRight2 from "./previewPage/compontes/cartRight2.vue"; //卡片2右边
import cartLeft from "./previewPage/compontes/cartLeft.vue"; //卡片配置  左边1
import activeTrend from "./previewPage/compontes/activeTrend.vue"; //应用活跃趋势 左边2
import activeTop5 from "./previewPage/compontes/activeTop5.vue"; //应用活跃Top5 左边3
import china from "./previewPage/compontes/china.vue"; //地图配置
import tables from "./previewPage/compontes/table.vue"; //表格配置
import cartRight from "./previewPage/compontes/cartRight.vue"; //卡片配置  右边1
import threatTrend from "./previewPage/compontes/threatTrend.vue"; //应用威胁趋势 右边2
import threatTop5 from "./previewPage/compontes/threatTop5.vue"; //应用威胁top5 右边3
export default {
  // 组件
  components: {
    Eldialog,
    cartLeft, //卡片配置 左边1
    cartLeft2,
    cartRight2,
    activeTrend, //应用活跃趋势 左2
    activeTop5, //应用活跃Top5 左边3
    cartRight, //卡片配置 右1
    threatTrend, //应用威胁趋势 右2
    threatTop5, //应用威胁Top5 右3
    china, //地图
    tables, //表格
  },
  data() {
    return {
      SituationDelayTime:vueConfig.SituationDelayTime,
      nameCityFlag: true,
      nameCityText: {},
      threatSwitch: this.$t('SecurityPosture.PreviewPage.threatSwitch') ,
      active: this.$t('SecurityPosture.PreviewPage.active'), //选中样式
      // timeSwitch:,
      // active:"",
      mapData: null, //图表
      queryCount: "0", //默认0
      echartData: null, //图表接收数据
      cardDataSum: null, //卡片接收数据
      appInstalls: { android: {}, ios: {} }, //应用安装Top5接收数据
      wholeTime: "", //整体时间
      nowDate: "", // 当前日期
      nowTime: "", // 当前时间
      nowWeek: "", // 当前星期
      //默认配置数据，可被接口配置覆盖
      pageContent: {
        cardInfo: {
          cardInfoLeft_1: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.PreviewPage.card.LeftOne'),
            appInfo: {
              appRunCountConf: true,
              appDangerCountConf: false,
              appEventCountConf: false,
              sdkBehaviorCountConf: false,
            },
            deviceInfo: {
              deviceSumCountConf: false,
              onLineCountConf: false,
              offLineCountConf: false,
              violationsSumCountConf: false,
              lostCountConf:false,			 
						  scrapCountConf:false,
              onLineRateConf:false //613设备在线率
            },
            userInfo: {
              userSumCountConf: false,
              addUserCountConf: false,
              normalUserCountConf: false,
              lockUserCountConf: false,
              stopUsingUserCountConf: false,
            },
            gatewayInfo: {
              deviceConnectCountConf: false,
              accessAppCountConf: false,
              userConnectCountConf: false,
            },
          },
          cardInfoLeft_2: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.PreviewPage.card.LeftTwo'),
            appInfo: {
              appRunCountConf: false,
              appDangerCountConf: true,
              appEventCountConf: false,
              sdkBehaviorCountConf: false,
            },
            deviceInfo: {
              deviceSumCountConf: false,
              onLineCountConf: false,
              offLineCountConf: false,
              violationsSumCountConf: false,
              lostCountConf:false,			 
						  scrapCountConf:false,
              onLineRateConf:false //613设备在线率
            },
            userInfo: {
              userSumCountConf: false,
              addUserCountConf: false,
              normalUserCountConf: false,
              lockUserCountConf: false,
              stopUsingUserCountConf: false,
            },
            gatewayInfo: {
              deviceConnectCountConf: false,
              accessAppCountConf: false,
              userConnectCountConf: false,
            },
          },
          cardInfoRight_1: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.PreviewPage.card.RightOne'),
            appInfo: {
              appRunCountConf: false,
              appDangerCountConf: false,
              appEventCountConf: true,
              sdkBehaviorCountConf: false,
            },
            deviceInfo: {
              deviceSumCountConf: false,
              onLineCountConf: false,
              offLineCountConf: false,
              violationsSumCountConf: false,
              lostCountConf:false,			 
						  scrapCountConf:false,
              onLineRateConf:false //613设备在线率
            },
            userInfo: {
              userSumCountConf: false,
              addUserCountConf: false,
              normalUserCountConf: false,
              lockUserCountConf: false,
              stopUsingUserCountConf: false,
            },
            gatewayInfo: {
              deviceConnectCountConf: false,
              accessAppCountConf: false,
              userConnectCountConf: false,
            },
          },
          cardInfoRight_2: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.PreviewPage.card.RightTwo'),
            appInfo: {
              appRunCountConf: false,
              appDangerCountConf: false,
              appEventCountConf: false,
              sdkBehaviorCountConf: true,
            },
            deviceInfo: {
              deviceSumCountConf: false,
              onLineCountConf: false,
              offLineCountConf: false,
              violationsSumCountConf: false,
              lostCountConf:false,			 
						  scrapCountConf:false,
              onLineRateConf:false //613设备在线率
            },
            userInfo: {
              userSumCountConf: false,
              addUserCountConf: false,
              normalUserCountConf: false,
              lockUserCountConf: false,
              stopUsingUserCountConf: false,
            },
            gatewayInfo: {
              deviceConnectCountConf: false,
              accessAppCountConf: false,
              userConnectCountConf: false,
            },
          },
        },
        chartInfo: {
          chartInfoLeft_1: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.PreviewPage.TheChart.chartInfoLeft_1'),
            appInfo: {
              contentConf: "appActiveTrendConf",
              appActiveTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appDangerTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appActiveTrendConf: {
                lineChart: true,
                topChart: false,
              },
              appDangerTrendConf: {
                lineChart: false,
                topChart: false,
              },
              appUseTimeAvgConf: {
                topChart: false,
              },
              appInstallTopConf: {
                //应用安装top5
                topChart: false,
              },
            },
            deviceInfo: {
              contentConf: "deviceActiveTrendConf",
              deviceActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorDistributeConf: {
                pieChart: false,
                ringMoreChart: false,
              },
              newJoinDeviceConf: {
                lineChart: false,
              },
              systemVersionConf: {
                //系统版本
                topChart: false,
              },//613新设备占比
              newDeviceRateConf:{
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              },
              deviceActiveRateConf:{//613终端活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            userInfo: {
              contentConf: "useAppTopConf",
              userActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              useAppTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              sensitiveWordsConf: {
                cloudChart: false,
                ringMoreChart: false,
                barTuChart: false,
              },
              noNetworkAccessConf: {
                barTuChart: false,
              },
              userActiveRateConf:{//613用户活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            gatewayInfo: {
              userAccessTopConf: {
                topChart: false,
              },
              deviceAccessTopConf: {
                topChart: false,
              },
              appAccessTopConf: {
                topChart: false,
              },
              sourceAccessTopConf: {
                topChart: false,
              },
              accessResultConf: {
                lineChart: false,
                topChart: false,
              },
              gatewayThroughputConf: {
                lineChart: false,
                topChart: false,
              },
              appFlowTopConf: {					
                topChart: false
              },
              devFlowTopConf: {					
                topChart: false
              },
              appFlowRateTopConf: {				
                topChart: false
              },
              devFlowRateTopConf: {				
                topChart: false
              },
              netDelayTrendConf: {				
                lineChart: false,
                topChart: false
              },
            },
          },
          chartInfoLeft_2: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.PreviewPage.TheChart.chartInfoLeft_2'),
            appInfo: {
              contentConf: "appActiveTopConf",
              appActiveTopConf: {
                pieChart: true,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appDangerTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              appDangerTrendConf: {
                lineChart: false,
                topChart: false,
              },
              appUseTimeAvgConf: {
                topChart: false,
              },
              appInstallTopConf: {
                //应用安装top5
                topChart: false,
              },
            },
            deviceInfo: {
              contentConf: "deviceActiveTrendConf",
              deviceActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorDistributeConf: {
                pieChart: false,
                ringMoreChart: false,
              },
              newJoinDeviceConf: {
                lineChart: false,
              },
              systemVersionConf: {
                //系统版本
                topChart: false,
              },//613新设备占比
              newDeviceRateConf:{
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              },
              deviceActiveRateConf:{//613终端活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            userInfo: {
              contentConf: "useAppTopConf",
              userActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              useAppTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              sensitiveWordsConf: {
                cloudChart: false,
                ringMoreChart: false,
                barTuChart: false,
              },
              noNetworkAccessConf: {
                barTuChart: false,
              },
              userActiveRateConf:{//613用户活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            gatewayInfo: {
              userAccessTopConf: {
                topChart: false,
              },
              deviceAccessTopConf: {
                topChart: false,
              },
              appAccessTopConf: {
                topChart: false,
              },
              sourceAccessTopConf: {
                topChart: false,
              },
              accessResultConf: {
                lineChart: false,
                topChart: false,
              },
              gatewayThroughputConf: {
                lineChart: false,
                topChart: false,
              },
              appFlowTopConf: {					
                topChart: false
              },
              devFlowTopConf: {					
                topChart: false
              },
              appFlowRateTopConf: {				
                topChart: false
              },
              devFlowRateTopConf: {				
                topChart: false
              },
              netDelayTrendConf: {				
                lineChart: false,
                topChart: false
              },
            },
          },
          chartInfoRight_1: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.PreviewPage.TheChart.chartInfoRight_1'),
            appInfo: {
              contentConf: "appDangerTrendConf",
              appActiveTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appDangerTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              appDangerTrendConf: {
                lineChart: false,
                topChart: true,
              },
              appUseTimeAvgConf: {
                topChart: false,
              },
              appInstallTopConf: {
                //应用安装top5
                topChart: false,
              },
            },
            deviceInfo: {
              contentConf: "deviceActiveTrendConf",
              deviceActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorDistributeConf: {
                pieChart: false,
                ringMoreChart: false,
              },
              newJoinDeviceConf: {
                lineChart: false,
              },
              systemVersionConf: {
                //系统版本
                topChart: false,
              },//613新设备占比
              newDeviceRateConf:{
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              },
              deviceActiveRateConf:{//613终端活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            userInfo: {
              contentConf: "useAppTopConf",
              userActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              useAppTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              sensitiveWordsConf: {
                cloudChart: false,
                ringMoreChart: false,
                barTuChart: false,
              },
              noNetworkAccessConf: {
                barTuChart: false,
              },
              userActiveRateConf:{//613用户活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            gatewayInfo: {
              userAccessTopConf: {
                topChart: false,
              },
              deviceAccessTopConf: {
                topChart: false,
              },
              appAccessTopConf: {
                topChart: false,
              },
              sourceAccessTopConf: {
                topChart: false,
              },
              accessResultConf: {
                lineChart: false,
                topChart: false,
              },
              gatewayThroughputConf: {
                lineChart: false,
                topChart: false,
              },
              appFlowTopConf: {					
                topChart: false
              },
              devFlowTopConf: {					
                topChart: false
              },
              appFlowRateTopConf: {				
                topChart: false
              },
              devFlowRateTopConf: {				
                topChart: false
              },
              netDelayTrendConf: {				
                lineChart: false,
                topChart: false
              },
            },
          },
          chartInfoRight_2: {
            showConf: "appInfo",
            titleName: this.$t('SecurityPosture.PreviewPage.TheChart.chartInfoRight_2'),
            appInfo: {
              contentConf: "appDangerTopConf",
              appActiveTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              appDangerTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: true,
                topChart: false,
              },
              appActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              appDangerTrendConf: {
                lineChart: false,
                topChart: false,
              },
              appUseTimeAvgConf: {
                topChart: false,
              },
              appInstallTopConf: {
                //应用安装top5
                topChart: false,
              },
            },
            deviceInfo: {
              contentConf: "deviceActiveTrendConf",
              deviceActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorTrendConf: {
                lineChart: false,
                topChart: false,
              },
              violaBehaviorDistributeConf: {
                pieChart: false,
                ringMoreChart: false,
              },
              newJoinDeviceConf: {
                lineChart: false,
              },
              systemVersionConf: {
                //系统版本
                topChart: false,
              },
              //613新设备占比
              newDeviceRateConf:{
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              },
              deviceActiveRateConf:{//613终端活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            userInfo: {
              contentConf: "useAppTopConf",
              userActiveTrendConf: {
                lineChart: false,
                topChart: false,
              },
              useAppTopConf: {
                pieChart: false,
                ringMoreChart: false,
                rosesChart: false,
                topChart: false,
              },
              sensitiveWordsConf: {
                cloudChart: false,
                ringMoreChart: false,
                barTuChart: false,
              },
              noNetworkAccessConf: {
                barTuChart: false,
              },
              userActiveRateConf:{//613用户活跃度
                ringMoreChart: false, //环形图
                pieChart: false, //饼图
                rosesChart: false //玫瑰图
              }
            },
            gatewayInfo: {
              userAccessTopConf: {
                topChart: false,
              },
              deviceAccessTopConf: {
                topChart: false,
              },
              appAccessTopConf: {
                topChart: false,
              },
              sourceAccessTopConf: {
                topChart: false,
              },
              accessResultConf: {
                lineChart: false,
                topChart: false,
              },
              gatewayThroughputConf: {
                lineChart: false,
                topChart: false,
              },
              appFlowTopConf: {					
                topChart: false
              },
              devFlowTopConf: {					
                topChart: false
              },
              appFlowRateTopConf: {				
                topChart: false
              },
              devFlowRateTopConf: {				
                topChart: false
              },
              netDelayTrendConf: {				
                lineChart: false,
                topChart: false
              },
            },
          },
        },
        tableInfo: {
          titleName: this.$t('SecurityPosture.PreviewPage.tableList.titleName'),
          showConf: "1",
        },
        mapInfo: {
          showConf: "appInfo",
          appInfo: {
            alarmInfoConf: true,
          },
          deviceInfo: {
            violaInfoConf: false,
          },
          userInfo: {
            drawInfoConf: false,
          },
        },
        titleInfo: {
          titleName: this.$t('SecurityPosture.PreviewPage.headerTitle.titleName'),
        },
      },
    };
  },
  created() {
    setTimeout(() => {
      this.tableList(); //列表查询
      this.CardInterface(); //卡片借口
      this.mapChartsData(); //地图接口
      this.chartsData(); //图表接口
      this.installApp(); //应用安装Top5接口
    }, 2000);
    
  },

  mounted() {
    setInterval(() => {
      this.tableList(); //列表查询
      this.CardInterface(); //卡片借口
      this.mapChartsData(); //地图接口
      this.chartsData(); //图表接口
      this.installApp(); //应用安装Top5接口
    }, this.SituationDelayTime);
    const loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    setTimeout(() => {
      loading.close();
    }, 2000);
    // 页面加载完后显示当前时间
    this.dealWithTime(new Date());
    this.installApp(); //应用安装Top5接口
    // this.chartsData(); //图表接口 
    // 定时刷新时间
    this.timer = setInterval(() => {
      this.dealWithTime(new Date()); // 修改数据date
    }, 1000);
  },
  methods: {
    nameCity(val) { 
      this.nameCityText = val;
      this.nameCityFlag = false;
      setTimeout(() => {
        this.nameCityFlag = true;
      }, 100);
    },
    changeData2(item) {
      if (item == "当天") {
        this.active = item;
        this.queryCount = "0";
        this.mapChartsData(); //地图接口
        this.chartsData(); //图表接口
        this.CardInterface(); //卡片借口
        this.installApp(); //查询安全态势二层数据
      } else if (item == "近7天") {
        this.active = item;
        this.queryCount = "1";
        this.mapChartsData(); //地图接口
        this.chartsData(); //图表接口
        this.CardInterface(); //卡片借口
        this.installApp(); //查询安全态势二层数据
      } else if (item == "近30天") {
        this.active = item;
        this.queryCount = "2";
        this.mapChartsData(); //地图接口
        this.chartsData(); //图表接口
        this.CardInterface(); //卡片借口
        this.installApp(); //查询安全态势二层数据
      } else if (item == "近90天") {
        this.active = item;
        this.queryCount = "3";
        this.mapChartsData(); //地图接口
        this.chartsData(); //图表接口
        this.CardInterface(); //卡片借口
        this.installApp(); //查询安全态势二层数据
      }
    },
    // switchFlag(item){ 
    //  if(item =='当天'){
    //   this.active =item;
    //   this.queryCount='0'
    //  }else if(item == '近7天'){
    //   this.active =item;
    //   this.queryCount='1'
    //  }else if(item == '近30天'){
    //   this.active =item;
    //   this.queryCount='2'
    //  }else if(item == '近90天'){
    //   this.active =item;
    //   this.queryCount='3'
    //  }
    //   this.mapChartsData(); //地图接口
    //   this.chartsData(); //图表接口
    //   this.CardInterface(); //卡片借口
    // },
    goOut() {
      // 强制刷新
      this.$router.go();
      // this.$router.push("/SourceHome");
    },

    // zeroQuery() {
    //   this.queryCount = "0";
    //   sessionStorage.setItem("queryCount",this.queryCount)
    //   this.mapChartsData(); //地图接口
    //   this.chartsData(); //图表接口
    //   this.CardInterface(); //卡片借口
    // },
    // oneQuery() {
    //   this.queryCount = "1";
    //   sessionStorage.setItem("queryCount",this.queryCount)
    //   this.mapChartsData(); //地图接口
    //   this.chartsData(); //图表接口
    //   this.CardInterface(); //卡片借口
    // },
    // twoQuery() {
    //   this.queryCount = "2";
    //   sessionStorage.setItem("queryCount",this.queryCount)
    //   this.mapChartsData(); //地图接口
    //   this.chartsData(); //图表接口
    //   this.CardInterface(); //卡片借口
    // },
    // threeQuery() {
    //   this.queryCount = "3";
    //   sessionStorage.setItem("queryCount",this.queryCount)
    //   this.mapChartsData(); //地图接口
    //   this.chartsData(); //图表接口
    //   this.CardInterface(); //卡片借口
    // },
    //
    async installApp() {
      var param = {
        timeType: this.queryCount, //0-当天 1-7天 2-30天 3-90天
        // reqType: "gatewayThroughput", //请求参数 gatewayThroughput-网关吞吐量数据 appInstall-应用安装数据 accessResult-网关业务访问趋势数据
      };
      const res = await this.$axios.post(
        "/httpServe/securitySituation/getOtherDataInfo",
        param,
        true
      );
      this.appInstalls = res.data;
    },
    // 列表
    async tableList() {
      var param = {
        // json
      };
      const res = await this.$axios.post(
        "/httpServe/securityPageConfig/selectConfig",
        param,
        true
      ); 
      if (res.data) {
        // this.$refs.tablesRef.child(JSON.parse(res.data));
        this.pageContent = JSON.parse(res.data); 
        // console.log(this.pageContent,"预览**********");
      }
    },
    //图形数据接口
    async chartsData() {
      var param = {
        timeType: this.queryCount,
        
      };
      const res = await this.$axios.post(
        "/httpServe/securitySituation/getChartDataInfo",
        param,
        true
      ); 
      this.echartData = res.data; //应用
    },

    //地图数据接口
    async mapChartsData() {
      var param = {
        timeType: this.queryCount,
      };
      const res = await this.$axios.post(
        "/httpServe/securitySituation/getMapDataInfo",
        param,
        true
      ); 
      // this.$refs.chinaRef.child(JSON.parse(res.data));
      if(res.data == null) {
        this.mapData = {
          appInfo: {
              alarmInfo: []
          },
          deviceInfo: {
              violaInfo:[]
          },
          userInfo: {
              name: [],
              num: []
          }
      }
      }else {
        this.mapData = res.data;
      }
       //图表  图表数据
      // console.log(this.mapData)
    },
    // 卡片接口
    async CardInterface() {
      var param = {
        timeType: this.queryCount,
      };
      const res = await this.$axios.post(
        "/httpServe/securitySituation/getCardDataInfo",
        param,
        true
      );
      this.cardDataSum = res.data; //应用 卡片数据 
    },
    goBack() {
      this.$router.push("/configuration");
    },
    dealWithTime(data) {
      // 获取当前时间
      let formatDateTime;
      let Y = data.getFullYear();
      let M = data.getMonth() + 1;
      let D = data.getDate();
      let H = data.getHours();
      let Min = data.getMinutes();
      let S = data.getSeconds();
      let W = data.getDay();
      H = H < 10 ? "0" + H : H;
      Min = Min < 10 ? "0" + Min : Min;
      S = S < 10 ? "0" + S : S;
      switch (W) {
        case 0:
          W = this.$t('SecurityPosture.PreviewPage.PresentTime.day');
          break;
        case 1:
          W = this.$t('SecurityPosture.PreviewPage.PresentTime.one');
          break;
        case 2:
          W = this.$t('SecurityPosture.PreviewPage.PresentTime.two');
          break;
        case 3:
          W = this.$t('SecurityPosture.PreviewPage.PresentTime.three');
          break;
        case 4:
          W = this.$t('SecurityPosture.PreviewPage.PresentTime.four');
          break;
        case 5:
          W = this.$t('SecurityPosture.PreviewPage.PresentTime.five');
          break;
        case 6:
          W = this.$t('SecurityPosture.PreviewPage.PresentTime.six');
          break;
        default:
          break;
      }
      this.nowDate = Y + "-" + M + "-" + D + "  ";
      this.nowWeek =this.$t('SecurityPosture.PreviewPage.PresentTime.week') + W;
      this.nowTime = H + ":" + Min + ":" + S + "  ";
      this.wholeTime = this.nowDate + this.nowTime + this.nowWeek;
      // formatDateTime = Y + "年" + M + "月" + D + "日 " + " 周" +W + H + ":" + Min + ":" + S;
    },
  },
};
</script>

<style lang="scss" scoped>
//1920分辨率
@media screen and (min-width: 1920px) and (max-width: 2040px) {
  // 表格占比
  .tableStyle {
    width: 100%;
    height: 28%;
    margin-top: 2%;
  }
  //  标头  左边占比
  .zuo {
    width: 24%;
    margin-top: 1.5%;
    margin-left: 0%;
  }
  //  标头  中间占比
  .zhong {
    width: 35%;
    text-align: center;
    font-size: 32px;
  }
  //  标头  右边占比
  .right {
    width: 25%;
    margin-top: 1.5%;
  }
}
//1680分辨率
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  // 表格占比
  ::v-deep .tableStyle {
    width: 100%;
    height: 28%;
    margin-top: 2%;
    z-index: 9;
  }
  //  标头  左边占比
  .zuo {
    width: 22%;
    margin-top: 1.5%;
    margin-left: 0%;
  }
  //  标头  中间占比
  .zhong {
    width: 43%;
    text-align: center;
    font-size: 32px;
  }
  //  标头  右边占比
  .right {
    width: 25%;
    margin-top: 1.5%;
  }
}
//2560分辨率
@media screen and (min-width: 2048px) and (max-width: 2560px) {
  // 表格占比
  ::v-deep .tableStyle {
    width: 100%;
    height: 28%;
    margin-top: 2%;
    z-index: 9;
  }
  //  标头  左边占比
  .zuo {
    width: 22%;
    margin-top: 1.5%;
    margin-left: 0%;
  }
  //  标头  中间占比
  .zhong {
    width: 35%;
    text-align: center;
    font-size: 42px;
  }
  //  标头  右边占比
  .right {
    width: 25%;
    margin-top: 1.5%;
  }
}
::v-deep .el-row .el-col-24 .el-col {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
  box-sizing: border-box;
}
::v-deep .el-row {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
::v-deep .el-table th > .cell {
  color: #01b9cd;
}
::v-deep .active {
  color: #08f2f0;
}
.zhong {
  letter-spacing: 5px;
  font-family: "Microsoft YaHei", 微软雅黑, "Microsoft JhengHei", 华文细黑, STHeiti,
    MingLiu;

  // 左右渐变
  //  background-image: linear-gradient(to right, orange, purple);
  //     -webkit-background-clip: text;
  //     color: transparent;
  // 冲上倒下
  background-image: linear-gradient(#2de2ff, #8b67fb);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
// 齿轮设置
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.taishi {
  width: 100%;
  height: 100%;
  // background: #030f3c;
  background: url("../assets/bg.png"),
    //背景图
    rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: auto;
  min-width: 100%;
}

.sevurbox {
  width: 100%;
  height: 88%;
  display: flex;
  justify-content: space-around;

  .left1 {
    width: 28%;
    height: 100%;
  }

  .center1 {
    width: 44%;
    height: 100%;
  }

  .right1 {
    width: 28%;
    height: 100%;
  }

  // // 齿轮设置
  // .icon {
  //   width: 2em;
  //   height: 2em;
  //   vertical-align: -0.15em;
  //   fill: currentColor;
  //   overflow: hidden;
  // }
  .beijing {
    width: 100%;
    height: 700px;
    background: url("../assets/line.gif"),
      //背景图
      rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>
